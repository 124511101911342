import { useEffect } from "react";

import PropTypes from "prop-types";

import differenceInMinutes from "date-fns/differenceInMinutes";
import FullCalendar, { DatesSetArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import svLocale from "@fullcalendar/core/locales/sv";

import { FULFILLED_UPDATE_METHOD } from "../../../features/dataTypes";

import SmallCalendarEvent from "../../shared/Calendar/SmallCalendarEvent";
import NormalCalendarEvent from "../../shared/Calendar/NormalCalendarEvent";
import { ResourceCalendarEventsFilter } from "../../../features/resourceCalendarEvent/dataTypes";
import useGetResourceCalendarEvents from "../../../features/resourceCalendarEvent/hooks/useGetResourceCalendarEvents";
import ReactTooltip from "react-tooltip";
import { ResponseResourceCalendarSettings } from "../../../features/resourceCalendarSettings/dataTypes";

const TIME_OPTIONS = {
  CALENDAR_TYPE_INTERVAL: 90,
  SLOT_DURATION_INTERVAL: 9 * 60,
  SLOT_DURATION_LONG_DAY: "01:00",
  SLOT_DURATION_SHORT_DAY: "00:30",
} as const;

const ResourceCalendar = ({
  height,
  settings,
  filter,
  setFilter,
}: {
  height: string;
  settings: ResponseResourceCalendarSettings;
  filter: ResourceCalendarEventsFilter;
  setFilter: (filter: ResourceCalendarEventsFilter) => void;
}) => {
  const timeDuration = (startDate: Date | string, endDate: Date | string) => {
    if (typeof startDate === "string") startDate = new Date(`1995-12-17T${startDate}`);
    if (typeof endDate === "string") endDate = new Date(`1995-12-17T${endDate}`);
    return differenceInMinutes(endDate, startDate);
  };

  const { resourceCalendarEvents, refetchResourceCalendarEvents } = useGetResourceCalendarEvents({
    filter: filter,
    fulfilledUpdateMethod: FULFILLED_UPDATE_METHOD.SET_ALL,
  });

  useEffect(() => {
    refetchResourceCalendarEvents();
  }, [filter, refetchResourceCalendarEvents]);

  const handleDates = (rangeInfo: DatesSetArg) => {
    setFilter({
      ...filter,
      fromDate: rangeInfo.start.toISOString().substring(0, 10),
      toDate: rangeInfo.end.toISOString().substring(0, 10),
    });
  };

  return (
    <div>
      <FullCalendar
        plugins={[timeGridPlugin, dayGridPlugin]}
        initialView={settings.viewMode}
        weekends={settings.showWeekends}
        locale={svLocale}
        allDaySlot={true}
        firstDay={1}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "timeGridDay,timeGridWeek",
        }}
        businessHours={{
          daysOfWeek: [1, 2, 3, 4, 5],
          startTime: settings.workDayStart,
          endTime: settings.workDayEnd,
        }}
        height={height}
        nowIndicator={true}
        slotDuration={{ minutes: 30 }}
        slotLabelInterval={{ hours: 1 }}
        events={resourceCalendarEvents}
        datesSet={handleDates}
        editable={false}
        weekNumbers={true}
        scrollTime={settings.scrollToTime}
        eventContent={function (arg) {
          return (
            <>
              {arg.event.start && arg.event.end ? (
                timeDuration(arg.event.start, arg.event.end) > TIME_OPTIONS.CALENDAR_TYPE_INTERVAL ? (
                  <NormalCalendarEvent arg={arg} />
                ) : (
                  <SmallCalendarEvent arg={arg} />
                )
              ) : (
                <SmallCalendarEvent arg={arg} />
              )}
              <ReactTooltip
                multiline={true}
                backgroundColor="LightGoldenRodYellow"
                textColor="Black"
                border={true}
                borderColor="Black"
              />
            </>
          );
        }}
      />
    </div>
  );
};

ResourceCalendar.propTypes = {
  height: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default ResourceCalendar;
