import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import { LogInRequest, ResponseSessionData, sessionSliceName, SessionState } from "../dataTypes";

async function login(dispatch: AppDispatch, user: LogInRequest) {
  let request: Request;

  if (!user.accessKey) {
    request = new Request(api.account.authentication(), Method.POST);
  } else {
    request = new Request(api.account.accessKeyAuthentication(), Method.POST);
  }

  return await sendRequest(request, user, await defaultRestOptions({ dispatch, includeSessionId: false }));
}

export const logIn = createAsyncThunk<ResponseSessionData, LogInRequest>(
  sessionSliceName + "/logIn",
  (dispatch, logInRequest) => login(dispatch, logInRequest),
);

export const addLogInReducers = (builder: ActionReducerMapBuilder<SessionState>) => {
  builder.addCase(logIn.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessage = undefined;
  });
  builder.addCase(logIn.fulfilled, (state, action) => {
    const { payload } = action;

    state.sessionData = { ...payload, fetchedTimestamp: Date.now(), requestState: REQUEST_STATE.FULFILLED };
    state.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(logIn.rejected, (state, action) => {
    state.requestState = REQUEST_STATE.REJECTED;
    state.errorMessage = action.payload?.message;
  });
};
