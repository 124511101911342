import { useEffect, useRef } from "react";
import useForceUpdate from "../../../hooks/useForceUpdate";
import { FulfilledUpdateMethod } from "../../dataTypes";
import { ControlPointPassagesFilter } from "../dataTypes";
import useLazyGetControlPointPassages from "./useLazyGetControlPointPassages";

const useGetControlPointPassages = ({
  filter,
  fulfilledUpdateMethod,
}: {
  filter?: ControlPointPassagesFilter;
  fulfilledUpdateMethod?: FulfilledUpdateMethod;
}) => {
  const {
    isLoading: isLazyLoading,
    fetchControlPointPassages,
    ...rest
  } = useLazyGetControlPointPassages({
    filter,
    fulfilledUpdateMethod,
  });

  const forceUpdate = useForceUpdate();

  const initiationStatus = useRef<"initiating" | "initiated">();

  useEffect(() => {
    const initiateControlPointPassages = async () => {
      if (!initiationStatus.current) {
        initiationStatus.current = "initiating";
        forceUpdate();
        await fetchControlPointPassages();
        initiationStatus.current = "initiated";
      }
    };

    initiateControlPointPassages();
  }, [fetchControlPointPassages, forceUpdate]);

  const isLoading = !initiationStatus.current || isLazyLoading;

  return { ...rest, isLoading, refetchControlPointPassages: fetchControlPointPassages };
};

export default useGetControlPointPassages;
