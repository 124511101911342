import { PaginationFilter } from "../utils/pagination";
import { AdditionalRequestState, IdObject } from "../dataTypes";

export const controlPointPassageSliceName = "controlPointPassage";

export interface ResponseControlPointPassage extends IdObject {
  controlPointName: string;
  destination: boolean;
  latitudeWGS84: string;
  longitudeWGS84: string;
  projectId: string;
  subProject: string;
  subContractor: string;
  date: string;
  time: string;
  description: string;
  responsible: string;
}

export interface ControlPointPassage extends ResponseControlPointPassage, AdditionalRequestState {}

export interface SlotPassage {
  x: string;
  y: number;
}

export interface ControlPointPassagesFilter extends PaginationFilter {
  freeTextSearch?: string;
  controlPointId?: string;
  fromDate?: string;
  toDate?: string;
  developmentAreaId?: string;
}
