const SESSION_INVALID = {
  CODE: "OTSS000",
  MESSAGE: "SESSION_EXPIRED",
} as const;

const WRONG_USERNAME_OR_PASSWORD = {
  CODE: "OTSU001",
  MESSAGE: "WRONG_USERNAME_OR_PASSWORD",
} as const;

export default { SESSION_INVALID, WRONG_USERNAME_OR_PASSWORD };
