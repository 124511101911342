import { useCallback, useEffect, useRef } from "react";
import { useAppSelector } from "../../../app/hooks";
import { ControlPointsFilter } from "../dataTypes";
import { FulfilledUpdateMethod, REQUEST_STATE } from "../../dataTypes";
import { selectCount, selectErrorMessage, selectRequestState, selectControlPoints } from "../controlPointSelectors";
import useFetchControlPoints from "./useFetchControlPoints";

const useLazyGetControlPoints = ({
  loadIfNotInitialized = false,
  filter,
  fulfilledUpdateMethod,
}: {
  loadIfNotInitialized?: boolean;
  filter?: ControlPointsFilter;
  fulfilledUpdateMethod?: FulfilledUpdateMethod;
}) => {
  const fetchControlPoints = useFetchControlPoints();
  const hasInitalized = useRef(false);

  const requestState = useAppSelector(selectRequestState);
  const errorMessage = useAppSelector(selectErrorMessage);
  const count = useAppSelector(selectCount);

  const controlPoints = useAppSelector(selectControlPoints);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isError = requestState === REQUEST_STATE.REJECTED;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;

  const loadControlPoints = useCallback(async () => {
    await fetchControlPoints({ filter, fulfilledUpdateMethod });
  }, [fetchControlPoints, filter, fulfilledUpdateMethod]);

  useEffect(() => {
    hasInitalized.current = false;
  }, [filter]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized && !hasInitalized.current) {
      hasInitalized.current = true;
      loadControlPoints();
    }
  }, [isUninitialized, loadControlPoints, loadIfNotInitialized]);

  return {
    controlPoints,
    count,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    errorMessage,
    fetchControlPoints: loadControlPoints,
  };
};

export default useLazyGetControlPoints;
