import cloneDeep from "lodash.clonedeep";
import { useCallback, useEffect } from "react";
import isEqual from "react-fast-compare";
import { PaginationFilter } from "../../utils/pagination";
import { FulfilledUpdateMethod, RequestState, REQUEST_STATE } from "../../dataTypes";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { fetchResources } from "../reducers/fetchResources";
import { selectRequestState } from "../resourceSelectors";

let filterRef: PaginationFilter | undefined;
let requestStateRef: RequestState | undefined;

const useFetchResources = () => {
  const dispatch = useAppDispatch();

  const requestState = useAppSelector(selectRequestState);

  if (requestStateRef === undefined) {
    requestStateRef = requestState;
  }

  useEffect(() => {
    requestStateRef = requestState;
  }, [requestState]);

  const loadResources = useCallback(
    async ({
      filter,
      fulfilledUpdateMethod,
    }: {
      filter?: Readonly<PaginationFilter>;
      fulfilledUpdateMethod: FulfilledUpdateMethod;
    }) => {
      if (!isEqual(filter, filterRef) || requestStateRef !== REQUEST_STATE.PENDING) {
        requestStateRef = REQUEST_STATE.PENDING;
        filterRef = cloneDeep(filter);
        await dispatch(fetchResources({ filter, fulfilledUpdateMethod }));
      }
    },
    [dispatch],
  );

  return loadResources;
};

export default useFetchResources;
