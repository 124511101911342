import { useState } from "react";
import ResourceCalendar from "./Calendar/ResourceCalendar";
import SelectField from "../shared/SelectField/SelectField";

import { useTranslate } from "../../language/i18n";

import { ResourceCalendarEventsFilter } from "../../features/resourceCalendarEvent/dataTypes";
import useLazyGetResources from "../../features/resource/hooks/useLazyGetResources";

import { FULFILLED_UPDATE_METHOD } from "../../features/dataTypes";
import useGetResourceCalendarSettings from "../../features/resourceCalendarSettings/hooks/useGetResourceCalendarSettings";

const ResourceCalendarPage = () => {
  const [filter, setFilter] = useState<ResourceCalendarEventsFilter>({
    fromDate: new Date().toISOString().substring(0, 10),
    toDate: new Date().toISOString().substring(0, 10),
    resourceCode: "",
  });

  const translate = useTranslate();

  const { resourceCalendarSettings } = useGetResourceCalendarSettings();

  const { resources } = useLazyGetResources({
    filter: { all: true },
    fulfilledUpdateMethod: FULFILLED_UPDATE_METHOD.SET_ALL,
    loadIfNotInitialized: true,
  });

  return (
    <>
      <SelectField
        options={resources.map(resource => {
          return { id: resource.resourceCode, value: `${resource.resourceCode} - ${resource.name}` };
        })}
        onSelect={selected => {
          setFilter({ ...filter, resourceCode: selected?.id });
        }}
        selectedId={filter.resourceCode}
        label={translate("CHOOSE_RESOURCE")}
      ></SelectField>
      <ResourceCalendar height="700px" settings={resourceCalendarSettings} filter={filter} setFilter={setFilter} />
    </>
  );
};

export default ResourceCalendarPage;
