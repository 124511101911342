import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RequestState, REQUEST_STATE } from "../../dataTypes";
import { setError } from "../../dialog/dialogSlice";
import { useValidateUserIsLoggedIn } from "../../utils/useValidateUserIsLoggedIn";
import { logOut as logOutAction } from "../reducers/logOut";
import { selectRequestState } from "../sessionSelectors";
import { handleClientSideSignOut } from "../utils/clientSideSignOut";

let requestStateRef: RequestState | undefined;

const useLogOut = () => {
  const dispatch = useAppDispatch();
  const [isLoggedOut, setIsLoggedOut] = useState<boolean>();
  const result = useValidateUserIsLoggedIn();

  const requestState = useAppSelector(selectRequestState);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isSuccess = isLoggedOut === true;
  const isError = requestState === REQUEST_STATE.REJECTED;

  if (requestStateRef === undefined) requestStateRef = requestState;

  useEffect(() => {
    return function cleanup() {
      setIsLoggedOut(false);
    };
  }, []);

  useEffect(() => {
    requestStateRef = requestState;
  }, [requestState]);

  const logOut = useCallback(async () => {
    setIsLoggedOut(false);

    if (result.isError) {
      dispatch(setError({ value: result.errorMessage }));
    } else {
      if (requestStateRef !== REQUEST_STATE.PENDING) {
        requestStateRef = REQUEST_STATE.PENDING;

        const payload = (await dispatch(logOutAction())).payload;

        if (!payload) {
          handleClientSideSignOut(dispatch);
          setIsLoggedOut(true);
        } else setIsLoggedOut(false);
      }
    }
  }, [dispatch, result]);

  return { logOut, isUninitialized, isLoading, isSuccess, isError };
};

export default useLogOut;
