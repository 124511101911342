import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { resourceCalendarSettingsSliceName } from "./dataTypes";

const selectSliceState = (state: RootState) => state[appDataName][resourceCalendarSettingsSliceName];

export const selectResourceCalendarSettings = (state: RootState) => selectSliceState(state).settings;

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessage;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;
