import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import { ResponseSessionData, sessionSliceName, SessionState } from "../dataTypes";

async function getSessionData(dispatch: AppDispatch) {
  const request = new Request(api.account.authentication(), Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchSessionData = createAsyncThunk<ResponseSessionData>(
  sessionSliceName + "/fetchSessionData",
  dispatch => getSessionData(dispatch),
);

export const addFetchSessionDataReducers = (builder: ActionReducerMapBuilder<SessionState>) => {
  builder.addCase(fetchSessionData.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessage = undefined;
  });
  builder.addCase(fetchSessionData.fulfilled, (state, action) => {
    const { payload } = action;

    state.sessionData = { ...payload, fetchedTimestamp: Date.now(), requestState: REQUEST_STATE.FULFILLED };
    state.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(fetchSessionData.rejected, (state, action) => {
    state.requestState = REQUEST_STATE.REJECTED;
    state.errorMessage = action.payload?.message;
  });
};
