import { ErrorCircle, Check } from "@myloc/myloc-gui";

const CalendarIcon = (icon: string) => {
  const ICONS = {
    ICON_SIZE: "18px",
    RED_WARNING: "redWarning",
    DELIVERED: "delivered",
    YELLOW_CHECK: "yellowCheck",
    GREEN_CHECK: "greenCheck",
  } as const;

  switch (icon) {
    case ICONS.RED_WARNING:
      return <ErrorCircle color="red" size={ICONS.ICON_SIZE} />;
    case ICONS.DELIVERED:
      return <Check size={ICONS.ICON_SIZE} />;
    case ICONS.YELLOW_CHECK:
      return <Check color="yellow" size={ICONS.ICON_SIZE} />;
    case ICONS.GREEN_CHECK:
      return <Check color="green" size={ICONS.ICON_SIZE} />;

    default:
      return null;
  }
};

export default CalendarIcon;
