import PropTypes from "prop-types";
import { ReactNode, useCallback, useEffect, useRef } from "react";

const OnClickOutside = ({ call, children }: { call: () => void; children: ReactNode }) => {
  const node = useRef<HTMLDivElement>(null);

  const listener = useCallback(
    (event: MouseEvent) => {
      if (node.current?.contains(event.target as HTMLInputElement)) {
        return;
      }

      call();
    },
    [call],
  );

  useEffect(() => {
    document.addEventListener("mouseup", listener);

    return () => {
      document.removeEventListener("mouseup", listener);
    };
  }, [listener]);

  return <div ref={node}>{children}</div>;
};

OnClickOutside.propTypes = {
  call: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
};

export default OnClickOutside;
