import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectSessionId } from "../../features/session/sessionSelectors";
import getSlideshowData from "./services/slideshowDataServices";
import HeaderSlideshow from "./SlideshowParts/HeaderSlideshow";
import PanelSlideshow from "./SlideshowParts/PanelSlideshow";
import styles from "./SlideshowPage.module.scss";
import { SlideshowType, SLIDESHOW_TYPES } from "./dataTypes";
import { useTranslate } from "../../language/i18n";

const OPTIONS = {
  UPDATE_FREQUENCY: 900000, // 1000 == 1s; 15min = 900000
  FETCH_FILTER: {
    calendarEvents: {
      filter: {
        all: true,
      },
      include: true,
    },
  },
} as const;

const SlideshowPage = ({
  height = "100vh",
  width = "100vw",
  headerHeight = "80px",
}: {
  height?: string;
  width?: string;
  headerHeight?: string;
}) => {
  const translate = useTranslate();

  const validateSlideshowData = (slideshowData: Partial<SlideshowType>): boolean => {
    if (!slideshowData.slideshow || !slideshowData.slideshow.projectId || !slideshowData.slideshow.projectName)
      return false;
    else {
      for (let i = 0; i < slideshowData.slideshow.slides.length; i++) {
        const slide = slideshowData.slideshow.slides[i];

        if (slide.type === SLIDESHOW_TYPES.CALENDAR) {
          if (!slide.workDayStart || !slide.workDayEnd) {
            return false;
          }
        }
      }

      return true;
    }
  };

  const slideshowPlaceholder: SlideshowType = {
    slideshow: {
      slides: [],
      companyLogoUrl: "Url placeholder",
      projectId: "0000000",
      projectName: "placeholder",
    },
    calendarEvents: [],
  };

  const [slideshowInfo, setSlideshowInfo] = useState<SlideshowType>(() => {
    const getSlideshowData = window.localStorage.getItem("slides");

    if (getSlideshowData === null || !validateSlideshowData(JSON.parse(getSlideshowData) as SlideshowType)) {
      return slideshowPlaceholder;
    } else {
      return JSON.parse(getSlideshowData) as SlideshowType;
    }
  });

  const dispatch = useAppDispatch();
  const sessionId = useAppSelector(selectSessionId);

  useEffect(() => {
    async function updateSlideshowData() {
      if (sessionId) {
        const slideshowData = await getSlideshowData(dispatch, OPTIONS.FETCH_FILTER);

        if (validateSlideshowData(slideshowData.data as SlideshowType)) {
          window.localStorage.setItem("slides", JSON.stringify(slideshowData.data));
          setSlideshowInfo(slideshowData.data as SlideshowType);
        }
      }
    }

    // first fetch
    updateSlideshowData();

    const intervals = setInterval(updateSlideshowData, OPTIONS.UPDATE_FREQUENCY);

    return () => clearInterval(intervals);
  }, [dispatch, sessionId]);

  return (
    <div className={styles.container} style={{ height: `${height}`, width: `${width}` }}>
      {slideshowInfo !== slideshowPlaceholder && slideshowInfo.slideshow.slides.length !== 0 ? (
        <>
          <HeaderSlideshow
            projectName={slideshowInfo.slideshow.projectName}
            projectID={slideshowInfo.slideshow.projectId}
            companyLogo={slideshowInfo.slideshow.companyLogoUrl}
            headerHeight={headerHeight}
          />
          <PanelSlideshow
            height={height}
            width={width}
            headerHeight={headerHeight}
            slides={slideshowInfo.slideshow.slides}
            calendarEvents={slideshowInfo.calendarEvents}
          />
        </>
      ) : (
        <div /* className={styles.connectionProblems} */>
          <p>{translate("SERVER_ERROR")}</p>
          <p>{translate("INTERNET_CONNECTION_ERROR_DESCRIPTION")} </p>
        </div>
      )}
    </div>
  );
};

SlideshowPage.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  headerHeight: PropTypes.string,
};

export default SlideshowPage;
