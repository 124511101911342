import { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslate } from "../../../../language/i18n";
import pages from "../../../../utils/pages";
import { createMenu } from "../../../../utils/menu/menu";

import Drawer from "../../../shared/Drawer/Drawer";
import styles from "./MenuDrawer.module.scss";
import useLogOut from "../../../../features/session/hooks/useLogOut";
import { MenuItem as MenuItemType } from "../../../../utils/menu/dataTypes";
import MenuItem from "./MenuItem/MenuItem";

function MenuDrawer({ visible, onMenuLinkClick }: { visible: boolean; onMenuLinkClick: () => void }) {
  const { logOut, isSuccess } = useLogOut();
  const translate = useTranslate();
  const history = useHistory();
  const [activeMenuItem, setActiveMenuItem] = useState<string>();
  const [subMenuVisible, setSubMenuVisible] = useState(false);
  const menuItems = createMenu("MENU_DRAWER");

  useEffect(() => {
    if (isSuccess) {
      history.push(pages.HOME);
    }
  }, [isSuccess, history]);

  const toggleMenu = (item?: MenuItemType) => {
    const isActiveItem = item && item.id === activeMenuItem;
    const hasSubMenus = item && !(item.to || (item.subMenus && item.subMenus.length < 0));

    if (isActiveItem && hasSubMenus) {
      setSubMenuVisible(subMenuVisible => !subMenuVisible);
    } else if (!hasSubMenus) {
      onMenuLinkClick();
    } else if (!isActiveItem) {
      setSubMenuVisible(subMenuVisible => !subMenuVisible);

      if (subMenuVisible) onMenuLinkClick();
    } else {
      setSubMenuVisible(false);

      onMenuLinkClick();
    }

    setActiveMenuItem(item?.id);
  };

  return (
    <Drawer title={translate("MENU")} visible={visible} customCssClass={styles.drawerMenu}>
      <nav className={styles.navContent}>
        <ul className={styles.menu}>
          {menuItems.map(item => (
            <MenuItem
              key={item.id}
              menuItem={item}
              toggleMenu={toggleMenu}
              isActive={item.id === activeMenuItem}
              subMenuVisible={subMenuVisible}
            />
          ))}
          <li className={styles.menuItem}>
            <button onClick={logOut} className={styles.menuButton}>
              {translate("LOGOUT")}
            </button>
          </li>
          <li className={styles.version}>
            {translate("VERSION")}: {process.env.REACT_APP_VERSION}
          </li>
        </ul>
      </nav>
    </Drawer>
  );
}

MenuDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onMenuLinkClick: PropTypes.func.isRequired,
};

export default memo(MenuDrawer);
