import { useCallback, useEffect, useRef } from "react";
import { useAppSelector } from "../../../app/hooks";
import { ControlPointPassagesFilter } from "../dataTypes";
import { FulfilledUpdateMethod, REQUEST_STATE } from "../../dataTypes";
import {
  selectCount,
  selectErrorMessage,
  selectRequestState,
  selectControlPointPassages,
} from "../controlPointPassageSelectors";
import useFetchControlPointPassages from "./useFetchControlPointPassages";

const useLazyGetControlPointPassages = ({
  loadIfNotInitialized = false,
  filter,
  fulfilledUpdateMethod,
}: {
  loadIfNotInitialized?: boolean;
  filter?: ControlPointPassagesFilter;
  fulfilledUpdateMethod?: FulfilledUpdateMethod;
}) => {
  const fetchControlPointPassages = useFetchControlPointPassages();
  const hasInitalized = useRef(false);

  const requestState = useAppSelector(selectRequestState);
  const errorMessage = useAppSelector(selectErrorMessage);
  const count = useAppSelector(selectCount);

  const controlPointPassages = useAppSelector(selectControlPointPassages);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isError = requestState === REQUEST_STATE.REJECTED;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;

  const loadControlPointPassages = useCallback(async () => {
    await fetchControlPointPassages({ filter, fulfilledUpdateMethod });
  }, [fetchControlPointPassages, filter, fulfilledUpdateMethod]);

  useEffect(() => {
    hasInitalized.current = false;
  }, [filter]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized && !hasInitalized.current) {
      hasInitalized.current = true;
      loadControlPointPassages();
    }
  }, [isUninitialized, loadControlPointPassages, loadIfNotInitialized]);

  return {
    controlPointPassages,
    count,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    errorMessage,
    fetchControlPointPassages: loadControlPointPassages,
  };
};

export default useLazyGetControlPointPassages;
