import { Link } from "react-router-dom";
import { useTranslate } from "../../../language/i18n";
import page from "../../../utils/pages";
import Menu from "./Menu/Menu";
import styles from "./Header.module.scss";
import MyPageHeader from "./MyPage/MyPageHeader";
import { useAppSelector } from "../../../app/hooks";
import { selectSessionId } from "../../../features/session/sessionSelectors";
import logo from "../../../assets/logos/Myloc-logga.svg";

function Header() {
  const userLoggedIn = !!useAppSelector(selectSessionId);
  const translate = useTranslate();

  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <h1 className={styles.hidden}>{translate("SITE_TITLE")}</h1>
        <div className={styles.logoContainer}>
          <figure>
            <Link to={page.HOME}>
              <img src={logo} alt="Myloc logo" className={styles.logo} />
            </Link>
          </figure>
          <span>{translate("HEADER_TITLE")}</span>
        </div>
        {userLoggedIn && (
          <div className={styles.rightMenu}>
            <MyPageHeader />
          </div>
        )}
      </div>
      <Menu />
    </header>
  );
}

export default Header;
