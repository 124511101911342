import { useEffect, useRef } from "react";
import useForceUpdate from "../../../hooks/useForceUpdate";
import { FulfilledUpdateMethod } from "../../dataTypes";
import useLazyGetResourceCalendarEvents from "./useLazyGetResourceCalendarEvents";
import { ResourceCalendarEventsFilter } from "../dataTypes";

const useGetResourceCalendarEvents = ({
  filter,
  fulfilledUpdateMethod,
}: {
  filter: Readonly<ResourceCalendarEventsFilter>;
  fulfilledUpdateMethod: FulfilledUpdateMethod;
}) => {
  const {
    isLoading: isLazyLoading,
    fetchResourceCalendarEvents,
    ...rest
  } = useLazyGetResourceCalendarEvents({ filter, fulfilledUpdateMethod });

  const forceUpdate = useForceUpdate();
  const initiationStatus = useRef<"initiating" | "initiated">();

  useEffect(() => {
    const initiateResourceCalendarEvents = async () => {
      if (!initiationStatus.current) {
        initiationStatus.current = "initiating";
        forceUpdate();
        await fetchResourceCalendarEvents();
        initiationStatus.current = "initiated";
      }
    };

    initiateResourceCalendarEvents();
  }, [fetchResourceCalendarEvents, forceUpdate]);

  const isLoading = !initiationStatus.current || isLazyLoading;

  return { ...rest, isLoading, refetchResourceCalendarEvents: fetchResourceCalendarEvents };
};

export default useGetResourceCalendarEvents;
