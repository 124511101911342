//import PropTypes from "prop-types";
import classNames from "classnames";
import { OptionalSelected } from "../../../../utils/dataTypes";
import { CustomSettingType, SelectFieldOption } from "../SelectField";
import { Check } from "@myloc/myloc-gui";
import styles from "../SelectField.module.scss";

const ListItem = <T extends SelectFieldOption>({
  item,
  settings,
  handleOnSelect,
  isSelected,
  isHighlighted,
}: {
  item: Readonly<T>;
  settings: OptionalSelected<Required<CustomSettingType<T>>, "template">;
  handleOnSelect: (item: T) => void;
  isSelected?: boolean;
  isHighlighted: boolean;
}) => {
  const template = (item: Readonly<T>) => {
    return <div>{settings.template ? settings.template(item) : item[settings.display]}</div>;
  };

  return (
    <li
      onClick={() => handleOnSelect(item)}
      className={classNames(styles.listItem, isHighlighted && styles.active, isSelected && styles.selected)}
    >
      {template(item)}
      {isSelected && <Check size="20" customCssClass={styles.icon} />}
    </li>
  );
};

/*
Problem with error for key if proptypes are used
ListItem.propTypes = {
  item: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  handleOnSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  isHighlighted: PropTypes.bool.isRequired,
} as unknown;*/

export default ListItem;
