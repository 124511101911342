import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { userSliceName } from "./dataTypes";
import { userAdapter } from "./userAdapter";

const selectSliceState = (state: RootState) => state[appDataName][userSliceName];

const adapterSelectors = userAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessage;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectUserEntities = adapterSelectors.selectEntities;
export const selectUsers = adapterSelectors.selectAll;
export const selectUser =
  (id = "") =>
  (state: RootState) =>
    adapterSelectors.selectById(state, id);
