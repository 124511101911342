import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { REQUEST_STATE } from "../../dataTypes";
import { ResponsePageContent } from "../../pageContent/dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import { CurrentPageContent, sessionSliceName, SessionState } from "../dataTypes";

async function getCurrentPageContent(dispatch: AppDispatch) {
  const request = new Request(api.pageContent.currentContent(), Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchCurrentPageContent = createAsyncThunk<ResponsePageContent>(
  sessionSliceName + "/fetchCurrentPageContent",
  dispatch => getCurrentPageContent(dispatch),
);

export const addFetchCurrentPageContentReducers = (builder: ActionReducerMapBuilder<SessionState>) => {
  builder.addCase(fetchCurrentPageContent.pending, state => {
    if (!state.currentPageContent) state.currentPageContent = { fetchedTimestamp: 0 } as CurrentPageContent;

    state.currentPageContent.requestState = REQUEST_STATE.PENDING;
    state.currentPageContent.errorMessage = undefined;
  });
  builder.addCase(fetchCurrentPageContent.fulfilled, (state, action) => {
    const { payload } = action;

    state.currentPageContent = {
      id: payload.id,
      fetchedTimestamp: Date.now(),
      requestState: REQUEST_STATE.FULFILLED,
    };
  });
  builder.addCase(fetchCurrentPageContent.rejected, (state, action) => {
    if (!state.currentPageContent) state.currentPageContent = {} as CurrentPageContent;

    state.currentPageContent.requestState = REQUEST_STATE.REJECTED;
    state.currentPageContent.errorMessage = action.payload?.message;
  });
};
