import { useCallback, useEffect } from "react";
import cloneDeep from "lodash.clonedeep";
import isEqual from "react-fast-compare";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { FulfilledUpdateMethod, RequestState, REQUEST_STATE } from "../../dataTypes";
import { ControlPointPassagesFilter } from "../dataTypes";
import { fetchControlPointPassages } from "../reducers/fetchControlPointPassages";
import { selectRequestState } from "../controlPointPassageSelectors";
import { useValidateUserIsLoggedIn } from "../../utils/useValidateUserIsLoggedIn";
import { setError } from "../../dialog/dialogSlice";

let filterRef: ControlPointPassagesFilter | undefined;
let requestStateRef: RequestState | undefined;

const useFetchControlPointPassages = () => {
  const dispatch = useAppDispatch();
  const result = useValidateUserIsLoggedIn();

  const requestState = useAppSelector(selectRequestState);

  if (requestStateRef === undefined) requestStateRef = requestState;

  useEffect(() => {
    requestStateRef = requestState;
  }, [requestState]);

  const loadControlPointPassages = useCallback(
    async ({
      filter,
      fulfilledUpdateMethod,
    }: {
      filter?: ControlPointPassagesFilter;
      fulfilledUpdateMethod?: FulfilledUpdateMethod;
    }) => {
      if (result.isError) {
        dispatch(setError({ value: result.errorMessage }));
      } else {
        if (!isEqual(filter, filterRef) || requestStateRef !== REQUEST_STATE.PENDING) {
          requestStateRef = REQUEST_STATE.PENDING;
          filterRef = cloneDeep(filter);

          const { payload } = await dispatch(fetchControlPointPassages({ filter, fulfilledUpdateMethod }));

          if (payload && "page" in payload) {
            return payload.page.map(({ id }) => id);
          }
        }
      }
    },
    [dispatch, result],
  );

  return loadControlPointPassages;
};

export default useFetchControlPointPassages;
