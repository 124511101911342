import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { controlPointSliceName } from "./dataTypes";
import { controlPointAdapter } from "./controlPointAdapter";
import { addFetchControlPointsReducers } from "./reducers/fetchControlPoints";

const slice = createSlice({
  name: controlPointSliceName,
  initialState: controlPointAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({ count: 0 }),
  reducers: {},
  extraReducers: builder => {
    addFetchControlPointsReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
