import { createSlice } from "@reduxjs/toolkit";
import { ResourceCalendarSettings, resourceCalendarSettingsSliceName } from "./dataTypes";
import { addFetchResourceCalendarSettingsReducers } from "./reducers/fetchResourceCalendarSettings";

const initialState: ResourceCalendarSettings = {
  settings: {
    workDayStart: "06:00",
    workDayEnd: "18:00",
    showWeekends: true,
    scrollToTime: "07:00",
    viewMode: "",
    id: "",
  },
};

const slice = createSlice({
  name: resourceCalendarSettingsSliceName,
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    addFetchResourceCalendarSettingsReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
