import { AdditionalRequestState, IdObject } from "../dataTypes";

export const resourceCalendarSettingsSliceName = "resourceCalendarSettings";

export interface ResponseResourceCalendarSettings extends IdObject {
  workDayStart: string;
  workDayEnd: string;
  showWeekends: boolean;
  viewMode: string;
  scrollToTime: string;
}

export interface ResourceCalendarSettings extends AdditionalRequestState {
  settings: ResponseResourceCalendarSettings;
}
