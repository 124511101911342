import { PaginationFilter } from "../utils/pagination";
import { AdditionalRequestState, IdObject } from "../dataTypes";

export const controlPointSliceName = "controlPoint";

export interface ResponseControlPoint extends IdObject {
  developmentArea: string;
  code: string;
  name: string;
  latitude: string;
  longitude: string;
  hasPassages?: boolean;
  scaling?: number;
}

export interface ControlPoint extends ResponseControlPoint, AdditionalRequestState {
  color?: string;
}

export interface ControlPointsFilter extends PaginationFilter {
  freeTextSearch?: string;
  developmentAreaId?: string;

  // TODO - Do I need?
  //sort?: ServiceTypesSorting[];
}

export interface ControlPointPassageFilter extends Omit<PaginationFilter, "sort"> {}

export interface ControlPointSelection {
  id: string;
  controlPoint: ControlPoint;
  color: string;
}
