import { ActionReducerMapBuilder, AsyncThunkPayloadCreator, EntityState } from "@reduxjs/toolkit";
import { AppDispatch } from "../app/store";
import { ValueOf } from "../utils/dataTypes";
import HttpStatusCodes from "../utils/HttpStatusCodes";

export interface RequestError {
  httpStatusCode: ValueOf<typeof HttpStatusCodes>;
  message: string;
}

export const REQUEST_STATE = {
  PENDING: "pending",
  FULFILLED: "fulfilled",
  REJECTED: "rejected",
} as const;

export type RequestState = ValueOf<typeof REQUEST_STATE>;

export const DATA_TYPE = {
  BOOLEAN: "BOOLEAN",
  STRING: "STRING",
  TEXT_AREA: "TEXT_AREA",
  INTEGER: "INTEGER",
  DECIMAL: "DECIMAL",
  DATE: "DATE",
  DATETIME: "DATETIME",
  TIME: "TIME",
  DAY_OF_WEEK: "DAY_OF_WEEK",
} as const;

export const PRESENTATION = {
  DEFAULT_INPUT: "DEFAULT_INPUT",
  RADIO_BUTTON_HORIZONTAL: "RADIO_BUTTON_HORIZONTAL",
  RADIO_BUTTON_VERTICAL: "RADIO_BUTTON_VERTICAL",
  DROP_DOWN: "DROP_DOWN",
  POPUP: "POPUP",
  CHECKBOX: "CHECKBOX",
} as const;

export interface IdObject {
  id: string;
}

export interface FetchedTimestamp {
  fetchedTimestamp: number;
}

export interface FetchedIdObject extends IdObject, FetchedTimestamp {}

export interface IdLabel extends IdObject {
  label?: string;
}

export interface IdType extends IdObject {
  type: string;
}

export interface Value {
  value: any;
}

export interface LabelValue extends Value {
  label: string;
}

export interface Owner {
  type: IdLabel;
  id: IdLabel;
}

export interface ExecutingOrganizationUnit {
  type: IdLabel;
  id: IdLabel;
}

export interface AdditionalRequestState {
  requestState?: RequestState;
  errorMessage?: string;
}

export interface ReferenceIds extends AdditionalRequestState {
  ids: string[];
}

export interface PaginationResponse<T> {
  count: number;
  page: T[];
}

export interface AdditionalPaginationState {
  count: number;
}

export interface Message {
  message: string;
}

export interface EntityActionReducerBuilderInterface<T, AdditionalState = unknown>
  extends ActionReducerMapBuilder<EntityState<T> & AdditionalRequestState & AdditionalState> {}

export type AsyncThunkRejectWithValue = Parameters<
  AsyncThunkPayloadCreator<any, any, { dispatch: AppDispatch; rejectValue: RequestError }>
>["1"]["rejectWithValue"];

export interface UseFetchRef extends FetchedTimestamp {
  requestState?: RequestState;
}

export interface UseFetchRefs {
  [id: string]: UseFetchRef | undefined;
}

export const FULFILLED_UPDATE_METHOD = {
  UPSERT_MANY: "UPSERT_MANY",
  SET_ALL: "SET_ALL",
} as const;

export type FulfilledUpdateMethod = ValueOf<typeof FULFILLED_UPDATE_METHOD>;

export const FETCH_MASTER_DATA_TIME_OUT = 20 * 1000;

export type OverloadedReturnType<T> = T extends {
  (...args: any[]): infer R;
  (...args: any[]): infer R;
  (...args: any[]): infer R;
  (...args: any[]): infer R;
}
  ? R
  : T extends {
      (...args: any[]): infer R;
      (...args: any[]): infer R;
      (...args: any[]): infer R;
    }
  ? R
  : T extends { (...args: any[]): infer R; (...args: any[]): infer R }
  ? R
  : T extends (...args: any[]) => infer R
  ? R
  : never;
