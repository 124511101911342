import PropTypes from "prop-types";
import styles from "./HeaderSlideshow.module.scss";
import logo from "../../../assets/logos/MyC.png";

const HeaderSlideshow = ({
  projectName,
  projectID,
  companyLogo,
  headerHeight,
}: {
  projectName: string;
  projectID: string;
  companyLogo: string;
  headerHeight: string;
}) => {
  return (
    <div className={styles.headerSlideshow} style={{ height: `${headerHeight}` }}>
      <div className={styles.logoBox}>
        <img className={styles.headerMyloc} src={logo} alt="MyLoc" />
      </div>

      <div className={styles.headerText}>
        {projectID} - {projectName}
      </div>

      <div className={styles.logoBox}>
        <img className={styles.companyLogo} src={companyLogo} alt={projectName} />
      </div>
    </div>
  );
};

HeaderSlideshow.propTypes = {
  projectName: PropTypes.string.isRequired,
  projectID: PropTypes.string.isRequired,
  companyLogo: PropTypes.string.isRequired,
  headerHeight: PropTypes.string.isRequired,
};

export default HeaderSlideshow;
