import { Method, Request, Response, sendRequest } from "@myloc/myloc-utils";
import { miniSerializeError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import i18n, { useTranslate } from "../../../language/i18n";
import { getErrorMessage } from "../../../services/error/errorService";
import { ValueOf } from "../../../utils/dataTypes";
import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { Message } from "../../dataTypes";
import { setError, setInfo } from "../../dialog/dialogSlice";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { getValidationResult } from "../../utils/getValidationResults";
import { RequestResetPasswordRequest } from "../dataTypes";

async function resetPassword(dispatch: AppDispatch, data: RequestResetPasswordRequest) {
  /**
   * Use a custom error handler to set a better descrption than sent from backend
   * The user sholud not see "not authorized" if user does not exist
   */
  const customErrorHandler: Parameters<typeof defaultRestOptions>["0"]["customErrorHandler"] = exception => {
    let status: ValueOf<typeof HttpStatusCodes> | undefined;
    let message: string | undefined;

    if (exception?.response?.status === HttpStatusCodes.UNAUTHORIZED) {
      //A non existing user has requested a password reset. Show standard message anyway together with status 200
      message = i18n.t("RESET_LINK_IS_SENT");
      status = HttpStatusCodes.OK;
    }

    //If no custom message is set, use message from exception
    if (!message) {
      message = getErrorMessage(exception);
    }

    dispatch(setInfo({ error: miniSerializeError(exception), value: message }));

    const responseStatus = status ?? exception?.response?.status;

    return new Response(responseStatus, message ?? "", exception);
  };

  const url = api.password.password();
  const request = new Request(url, Method.POST);

  return await sendRequest(request, data, await defaultRestOptions({ customErrorHandler, dispatch }));
}

const useRequestResetPassword = () => {
  const translate = useTranslate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const validateRequestResetPassword = useCallback(
    (request?: Partial<RequestResetPasswordRequest>) => {
      if (!request) return getValidationResult(true, translate("DATA_MISSING"), HttpStatusCodes.BAD_REQUEST);
      if (!request.username)
        return getValidationResult(true, translate("USERNAME_MISSING"), HttpStatusCodes.BAD_REQUEST);
      return getValidationResult(false);
    },
    [translate],
  );

  const requestResetPassword = useCallback(
    async (request: RequestResetPasswordRequest) => {
      setIsLoading(true);
      setIsSuccess(false);
      setIsError(false);
      setErrorMessage(undefined);

      let result = validateRequestResetPassword(request);

      if (result.isError) {
        dispatch(setError({ value: result.errorMessage }));
      } else {
        const response = await resetPassword(dispatch, request);

        if (!response.isAccepted())
          result = getValidationResult(true, response.message, response.statusCode as ValueOf<typeof HttpStatusCodes>);
        else dispatch(setInfo({ value: (response.data as Message).message }));
      }

      setIsSuccess(!result.isError);
      setIsError(result.isError);

      if (result.isError) setErrorMessage(result.errorMessage);

      setIsLoading(false);
    },
    [dispatch, validateRequestResetPassword],
  );

  return { requestResetPassword, isLoading, isSuccess, isError, errorMessage };
};

export default useRequestResetPassword;
