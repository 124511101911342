const pages = Object.freeze({
  HOME: "/",
  EMBEDDED_CONTROL_POINTS: "/embedded/control-points",
  SLIDESHOW: "/slideshow",
  COMFLOW_LOGIN: "/comflow-login",
  COMPANY_SETTINGS: "/company-settings",
  PROJECT_SETTINGS: "/project-settings",
  LOGISTICS_CENTER: "/logistics-center",
  RESOURCECALENDAR: "/embedded/resource-calendar",
  REGISTER: "account/register",
  RESET_PASSWORD: {
    PATH: "/password/reset",
    NAME: "RESET_PASSWORD_TITLE",
  },
} as const);

export default pages;
