import PropType from "prop-types";
import { VictoryChart, VictoryAxis, VictoryBar, VictoryStack, VictoryLabel } from "victory";
import { ControlPointPassage } from "../../../features/controlPointPassage/dataTypes";
import { ControlPoint } from "../../../features/controlPoint/dataTypes";

import styles from "./MultiControlPointGraph.module.scss";

import { useTranslate } from "../../../language/i18n";

const MultiControlPointGraph = ({
  controlPointInfo,
}: {
  controlPointInfo: Array<{
    id: string;
    controlPoint: ControlPoint;
    color: string;
    passages: Array<ControlPointPassage>;
  }>;
}) => {
  const translate = useTranslate();
  const dataByControlPoint = controlPointInfo
    .filter(info => info.passages.length > 0)
    .map(info => {
      const newData: Array<{ x: number; y: number }> = [];

      for (const passage of info.passages) {
        const currentX = parseInt(passage.time.substring(0, 2));

        if (newData.find(data => data.x === currentX)) {
          newData[newData.findIndex(data => data.x === currentX)].y++;
        } else {
          newData.push({ x: currentX, y: 1 });
        }
      }

      newData.sort((a, b) => a.x - b.x);

      return { id: info.id, data: newData, colorScale: info.color };
    });

  const getTicks = () => {
    let minX = 24;
    let maxX = 0;

    for (const pointInfo of dataByControlPoint) {
      for (const data of pointInfo.data) {
        minX = Math.min(minX, data.x);
        maxX = Math.max(maxX, data.x);
      }
    }

    const ticks = [];

    for (let i = 0; i < 24; i++) {
      if (i >= minX && i <= maxX) {
        ticks.push(i);
      }
    }

    return ticks;
  };

  const createVictoryGroup: () => JSX.Element = () => {
    const labels = dataByControlPoint
      .reduce<Array<{ x: number; y: number; label: number }>>((prev, curr) => {
        const newAcc = [...prev];

        for (const coord of curr.data) {
          const indexOfCoord = newAcc.findIndex(label => label.x === coord.x);

          if (indexOfCoord < 0) {
            newAcc.push({ x: coord.x, y: 0, label: coord.y });
          } else {
            newAcc[indexOfCoord] = { x: coord.x, y: 0, label: newAcc[indexOfCoord].label + coord.y };
          }
        }

        return newAcc;
      }, [])
      .sort((a, b) => a.x - b.x);

    return (
      <VictoryStack colorScale={dataByControlPoint.map(data => data.colorScale)} style={{ labels: { fontSize: 10 } }}>
        {dataByControlPoint.map(vData => (
          <VictoryBar
            key={vData.id}
            barWidth={12}
            data={vData.data}
            labels={({ datum }) => {
              return datum.y > 0 ? `${datum.y}` : null;
            }}
            labelComponent={<VictoryLabel dy={15} />}
          />
        ))}
        <VictoryBar data={labels} />
      </VictoryStack>
    );
  };

  return controlPointInfo.length > 0 ? (
    <div className={styles.chartContainer}>
      <VictoryChart padding={{ left: 50, right: 20, top: 50, bottom: 50 }} domainPadding={{ x: 25, y: [0, 0] }}>
        {createVictoryGroup()}

        <VictoryAxis
          style={{
            axisLabel: { fontSize: 10, padding: 20 },
            tickLabels: { fontSize: 10, padding: 5 },
          }}
          dependentAxis
          tickFormat={tick => (tick % 1 === 0 ? tick : "")}
          label={translate("NUMBER_OF_PASSAGES")}
        />
        <VictoryAxis
          style={{
            axisLabel: { fontSize: 10, padding: 20 },
            tickLabels: { fontSize: 10, padding: 5 },
          }}
          tickValues={getTicks()}
          tickFormat={tick => tick.toString().padStart(2, "0")}
        />
      </VictoryChart>
    </div>
  ) : null;
};

MultiControlPointGraph.propTypes = {
  controlPointInfo: PropType.array.isRequired,
};

export default MultiControlPointGraph;
