import { AdditionalRequestState, FetchedIdObject, IdObject } from "../dataTypes";

export const fileSliceName = "file";

export interface ResponseFile extends IdObject {
  name: string;
  description?: string;
  url: string;
  sequence: number;
  createdTimestamp?: number;
}

export interface DownloadFile extends ResponseFile {}
export interface File extends ResponseFile, AdditionalRequestState, FetchedIdObject {}
