import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { controlPointPassageSliceName } from "./dataTypes";
import { controlPointPassageAdapter } from "./controlPointPassageAdapter";
import { addFetchControlPointPassagesReducers } from "./reducers/fetchControlPointPassages";

const slice = createSlice({
  name: controlPointPassageSliceName,
  initialState: controlPointPassageAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addFetchControlPointPassagesReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
