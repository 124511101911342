import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import classNames from "classnames";
import { useMobile } from "../../utils/viewport";

import pages from "../../utils/pages";
import styles from "./Site.module.scss";

//import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import MobileHeader from "./Header/MobileHeader/MobileHeader";
import MobileMenu from "./MobileMenu/MobileMenu";
import NotFoundPage from "../shared/NotFound/NotFoundPage";
import ResetPasswordPage from "../Start/ResetPassword/ResetPasswordPage";
import StartPage from "../Start/StartPage";

import SlideshowPage from "../Slideshow/SlideshowPage";

import UserFeedback from "../shared/UserFeedback/UserFeedback";
import { useAppSelector } from "../../app/hooks";
import { selectIsInitialized, selectSessionId } from "../../features/session/sessionSelectors";
import useInit from "../../utils/useInit";
import LoadingSpinner from "../shared/LoadingSpinner/LoadingSpinner";
import TrafficNodesPage from "../TrafficNodes/TrafficNodesPage";
import ResourceCalendar from "../ResourceCalendar/ResourceCalendarPage";
import ComflowPage from "../Comflow/ComflowPage";

const Site = () => {
  useInit();

  const isSmallScreen = useMobile();
  const userLoggedIn = !!useAppSelector(selectSessionId);
  const isInitialized = useAppSelector(selectIsInitialized);

  if (!isInitialized) {
    return <LoadingSpinner />;
  }

  if (window.location.pathname.match(/^\/embedded\//)) {
    return (
      <Router>
        <EmbeddedRouter />
        <UserFeedback />
      </Router>
    );
  }

  if (window.location.pathname.match(/^\/slideshow/)) {
    return (
      <Router>
        <SlideshowPage headerHeight="100px" />
      </Router>
    );
  }

  if (window.location.pathname.match(/^\/trafficnodes/)) {
    return (
      <Router>
        <TrafficNodesPage />
      </Router>
    );
  }

  return (
    <Router>
      {isSmallScreen ? <MobileHeader /> : <Header />}
      <main className={classNames(styles.main, isSmallScreen && userLoggedIn && styles.loggedInMobile)}>
        <PageRouter />
        <UserFeedback />
      </main>
      {/*<Footer />*/}
      {isSmallScreen && <MobileMenu />}
    </Router>
  );
};

const PageRouter = () => {
  const sessionId = useAppSelector(selectSessionId);

  console.log("window.location.pathname", window.location.pathname);

  return (
    <>
      {!sessionId ? (
        <Switch>
          <Route exact path={pages.HOME}>
            <StartPage desiredPage={window.location.pathname + window.location.search} />
          </Route>

          <Route path={pages.RESET_PASSWORD.PATH}>
            <ResetPasswordPage />
          </Route>
          <Redirect to={pages.HOME} />
        </Switch>
      ) : (
        <Switch>
          <Route exact path={pages.HOME}>
            <StartPage />
          </Route>
          <Route exact path={pages.COMPANY_SETTINGS}>
            <ComflowPage newTask="Project/CPCompanyCenter" />
          </Route>
          <Route exact path={pages.PROJECT_SETTINGS}>
            <ComflowPage newTask="Project/CPConstructionProjects" />
          </Route>
          <Route exact path={pages.LOGISTICS_CENTER}>
            <ComflowPage newTask="Project/CPLogisticsCenterDelivery" />
          </Route>

          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      )}
    </>
  );
};

const EmbeddedRouter = () => {
  const sessionId = useAppSelector(selectSessionId);

  if (!sessionId) {
    return <></>;
  }

  return (
    <Switch>
      <Route exact path={pages.EMBEDDED_CONTROL_POINTS}>
        <TrafficNodesPage />
      </Route>
      <Route path={pages.RESOURCECALENDAR}>
        <ResourceCalendar />
      </Route>
    </Switch>
  );
};

export default Site;
