import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import defaultRestOptions from "../../../services/utils/defaultRestOptions";
import { REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import {
  ResourceCalendarSettings,
  resourceCalendarSettingsSliceName,
  ResponseResourceCalendarSettings,
} from "../dataTypes";

async function getResourceCalendarSettings(dispatch: AppDispatch) {
  const request = new Request(api.resourceCalendarSettings.getResourceCalendarSettings(), Method.POST);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchResourceCalendarSettings = createAsyncThunk<ResponseResourceCalendarSettings>(
  resourceCalendarSettingsSliceName + "/fetchResourceCalendarSettings",
  dispatch => getResourceCalendarSettings(dispatch),
);

export const addFetchResourceCalendarSettingsReducers = (
  builder: ActionReducerMapBuilder<ResourceCalendarSettings>,
) => {
  builder.addCase(fetchResourceCalendarSettings.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessage = undefined;
  });
  builder.addCase(fetchResourceCalendarSettings.fulfilled, (state, action) => {
    const { payload } = action;

    state.settings = { ...payload };
    state.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(fetchResourceCalendarSettings.rejected, (state, action) => {
    state.requestState = REQUEST_STATE.REJECTED;
    state.errorMessage = action.payload?.message;
  });
};
