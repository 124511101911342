import PropType from "prop-types";
import { VictoryChart, VictoryAxis, VictoryBar } from "victory";
import { ControlPointPassage } from "../../../features/controlPointPassage/dataTypes";

import styles from "./ControlPointGraph.module.scss";
import colors from "../../../style/colors.scss";

import { useTranslate } from "../../../language/i18n";

const ControlPointGraph = ({ controlPointPassages }: { controlPointPassages: Array<ControlPointPassage> }) => {
  const translate = useTranslate();
  const coordinates: Array<{ x: string; y: number }> = [];

  for (const passage of controlPointPassages) {
    const indexOfPassageTime = coordinates.findIndex(coordinate => coordinate.x == passage.time.substring(0, 2));

    if (indexOfPassageTime > -1) {
      coordinates[indexOfPassageTime] = {
        x: coordinates[indexOfPassageTime].x,
        y: coordinates[indexOfPassageTime].y + 1,
      };
    } else {
      coordinates.push({ x: passage.time.substring(0, 2), y: 1 });
    }

    coordinates.sort((a, b) => a.x.localeCompare(b.x));
  }

  return coordinates.length > 0 ? (
    <div className={styles.chartContainer}>
      <VictoryChart padding={{ left: 50, right: 50, top: 50, bottom: 50 }} domainPadding={{ x: 50, y: [0, 0] }}>
        <VictoryBar
          data={coordinates}
          style={{
            labels: { fontSize: 10 },
            data: { fill: colors.primaryDark, width: 6 },
          }}
          labels={({ datum }) => datum.y}
        />
        <VictoryAxis
          style={{
            axisLabel: { fontSize: 10, padding: 20 },
            tickLabels: { fontSize: 10, padding: 5 },
          }}
          dependentAxis
          tickFormat={tick => (tick % 1 === 0 ? tick : "")}
          label={translate("NUMBER_OF_PASSAGES")}
        />
        <VictoryAxis
          style={{
            axisLabel: { fontSize: 10, padding: 20 },
            tickLabels: { fontSize: 10, padding: 5 },
          }}
          tickFormat={tick => `${tick}`}
        />
      </VictoryChart>
    </div>
  ) : null;
};

ControlPointGraph.propTypes = {
  controlPointPassages: PropType.array.isRequired,
};

export default ControlPointGraph;
