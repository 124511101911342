import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { resourceSliceName } from "./dataTypes";
import { addFetchResourcesReducers } from "./reducers/fetchResources";
import { resourceAdapter } from "./resourceAdapter";

const slice = createSlice({
  name: resourceSliceName,
  initialState: resourceAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({ count: 0 }),
  reducers: {},
  extraReducers: builder => {
    addFetchResourcesReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
