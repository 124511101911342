import { ValueOf } from "../../utils/dataTypes";
import { FILTER_OPERATOR, ORDER_BY_OPERATOR } from "./SqlOperators";

export const QUERY_PARAM = {
  FROM: "from",
  AMOUNT: "amount",
  ALL: "all",
  SORT: "sort",
} as const;

export const FILTRATION_FILTER_DISCRIMINATOR = "FiltrationFilter";

export interface FiltrationFilter {
  id: any;
  value: ValueOf<typeof FILTER_OPERATOR>;
  discriminator: typeof FILTRATION_FILTER_DISCRIMINATOR;
}

export const SORT_FILTER_DISCRIMINATOR = "SortFilter";

export interface SortFilter {
  id: string;
  order: ValueOf<typeof ORDER_BY_OPERATOR>;
  discriminator: typeof SORT_FILTER_DISCRIMINATOR;
}

export interface PaginationFilter {
  [QUERY_PARAM.ALL]?: boolean;
  [QUERY_PARAM.AMOUNT]?: number;
  [QUERY_PARAM.FROM]?: number;
  [QUERY_PARAM.SORT]?: SortFilter[];
}

export const DEFAULT_QUERY_PARAM = {
  FROM: 0,
  AMOUNT: 40,
  ALL: false,
} as const;
