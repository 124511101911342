import { Request, Method, sendRequest } from "@myloc/myloc-utils";
import { api } from "../../../config/settings";
import defaultRestOptions from "../../../features/utils/defaultRestOptions";
import { AppDispatch } from "../../../app/store";

async function getSlideshowData(dispatch: AppDispatch, filter: any) {
  const url = api.slideshow.content();
  const request = new Request(url, Method.POST);

  return await sendRequest(request, filter, await defaultRestOptions({ dispatch, includeSessionId: true }));
}

export default getSlideshowData;
