import { combineReducers } from "@reduxjs/toolkit";
import { fileSliceName } from "../features/file/dataTypes";
import fileSlice from "../features/file/fileSlice";
import { hyperlinkSliceName } from "../features/hyperlink/dataTypes";
import hyperlinkSlice from "../features/hyperlink/hyperlinkSlice";
import { pageContentSliceName } from "../features/pageContent/dataTypes";
import pageContentSlice from "../features/pageContent/pageContentSlice";
import { passwordPolicySliceName } from "../features/passwordPolicy/dataTypes";
import passwordPolicySlice from "../features/passwordPolicy/passwordPolicySlice";
import { userSliceName } from "../features/user/dataTypes";
import userSlice from "../features/user/userSlice";
import { userAccessMessageSliceName } from "../features/userAccessMessage/dataTypes";
import userAccessMessageSlice from "../features/userAccessMessage/userAccessMessageSlice";
import { controlPointSliceName } from "../features/controlPoint/dataTypes";
import controlPointSlice from "../features/controlPoint/controlPointSlice";
import { controlPointPassageSliceName } from "../features/controlPointPassage/dataTypes";
import controlPointPassageSlice from "../features/controlPointPassage/controlPointPassageSlice";
import { resourceCalendarSettingsSliceName } from "../features/resourceCalendarSettings/dataTypes";
import resourceCalendarSettingsSlice from "../features/resourceCalendarSettings/resourceCalendarSettingsSlice";
import { resourceCalendarEventSliceName } from "../features/resourceCalendarEvent/dataTypes";
import resourceCalendarEventSlice from "../features/resourceCalendarEvent/resourceCalendarEventSlice";
import { resourceSliceName } from "../features/resource/dataTypes";
import resourceSlice from "../features/resource/resourceSlice";

const appDataReducer = combineReducers({
  [fileSliceName]: fileSlice,
  [hyperlinkSliceName]: hyperlinkSlice,
  [pageContentSliceName]: pageContentSlice,
  [passwordPolicySliceName]: passwordPolicySlice,
  [userAccessMessageSliceName]: userAccessMessageSlice,
  [userSliceName]: userSlice,
  [controlPointSliceName]: controlPointSlice,
  [controlPointPassageSliceName]: controlPointPassageSlice,
  [resourceCalendarSettingsSliceName]: resourceCalendarSettingsSlice,
  [resourceCalendarEventSliceName]: resourceCalendarEventSlice,
  [resourceSliceName]: resourceSlice,
});

export default appDataReducer;
