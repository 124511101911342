import PropTypes from "prop-types";
import styles from "./NormalCalendarEvent.module.scss";
import { EventContentArg } from "@fullcalendar/react";
import useGetCalendarPattern from "../../../../../hooks/useGetCalendarPattern";
import CalendarIcon from "./CalendarIcon";
import classNames from "classnames";

const NormalCalendarEvent = ({ arg }: { arg: EventContentArg }) => {
  const event = arg.event;
  const eventExtend = event.extendedProps;
  const pattern: string | null = useGetCalendarPattern(eventExtend.pattern);

  return (
    <div className={classNames(styles.calendarEventNormal, pattern)}>
      <div
        className={styles.iconText}
        style={{
          color: "#000",
          backgroundColor: event.extendedProps.headerColor,
        }}
      >
        {CalendarIcon(eventExtend.icon)}
        <div>
          {arg.timeText} {event.title}
        </div>
      </div>

      <div
        style={{
          color: `${eventExtend.textColor === "" ? "#000" : eventExtend.textColor}`,
        }}
      >
        {eventExtend.bodyText}
      </div>
    </div>
  );
};

NormalCalendarEvent.propTypes = {
  arg: PropTypes.object.isRequired,
};

export default NormalCalendarEvent;
