/* eslint-disable react/prop-types */
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useAppSelector } from "../../app/hooks";
import { selectSessionId } from "../../features/session/sessionSelectors";
import { useLocation, useHistory } from "react-router-dom";

const ComflowPage = ({ newTask }) => {
  const [response, setResponse] = useState("");
  const sessionId1 = useAppSelector(selectSessionId);
  const location = useLocation();
  const history = useHistory();
  const iframeRef = useRef(null);

  useEffect(() => {
    console.log("location", location);
    console.log("history", history);
    console.log("new task", newTask);

    const oldTask = sessionStorage.getItem("Task");

    console.log("old task", oldTask);

    console.log("Nu kör vi!");

    const webapp = process.env.REACT_APP_WEBAPP;
    let src = webapp + `/portal/?_startTask=${newTask}`;
    const sessionId = sessionStorage.getItem("ComflowSessionId");

    if (sessionId) {
      //src = webapp + `/portal/`;
      src = src + `&sessionId=${sessionId}`;
    } else {
      headers = {
        forceNewSessionId: "forceNewSessionId",
      };
    }

    if (oldTask !== newTask) {
      sessionStorage.setItem("Task", newTask);
    } else {
      src = webapp + `?sessionId=${sessionId}`;
    }

    let headers = {};

    console.log("src", src);

    axios
      .get(src, {
        headers: headers,
      })
      .then(response => {
        const html = response.data;
        const sessionIndex = html.indexOf("sessionId");
        const startIndex = html.substring(sessionIndex).indexOf("'") + sessionIndex;
        const endIndex = html.substring(startIndex + 1).indexOf("'") + startIndex;
        const sessionId = html.substring(startIndex + 1, endIndex + 1);

        if (sessionId && !sessionStorage.getItem("ComflowSessionId")) {
          sessionStorage.setItem("ComflowSessionId", sessionId);
        }

        setResponse(html);

        if (oldTask !== newTask) {
          axios.post(
            `https://construction-test222.myloc.se/mylocTEST/rs/construction/v1/closeAllComflowTabs/${sessionId}`,
            "",
            {
              headers: {
                SessionId: sessionId1,
              },
            },
          );
        }
      });
  }, [sessionId1, location]);

  return response ? (
    <iframe ref={iframeRef} srcDoc={response} width="100%" height="100%" scrolling="yes"></iframe>
  ) : null;
};

export default ComflowPage;
