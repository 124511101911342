import PropTypes from "prop-types";
import styles from "./SmallCalendarEvent.module.scss";
import { EventContentArg } from "@fullcalendar/react";
import useGetCalendarPattern from "../../../../../hooks/useGetCalendarPattern";
import CalendarIcon from "./CalendarIcon";
import classNames from "classnames";

const SmallCalendarEvent = ({ arg }: { arg: EventContentArg }) => {
  const event = arg.event;
  const eventExtend = event.extendedProps;
  const pattern: string | null = useGetCalendarPattern(eventExtend.pattern);

  return (
    <div className={classNames(styles.calendarEventSmall, pattern)} style={{ overflow: "hidden" }}>
      <div className={styles.iconText}>
        <div
          className={styles.iconText}
          style={{
            color: `${"#000"}`,
            backgroundColor: event.extendedProps.headerColor,
          }}
        >
          {CalendarIcon(eventExtend.icon)}
          <div>{arg.timeText}</div>
        </div>
        <div> {event.title} </div>
      </div>

      <div
        style={{
          color: `${eventExtend.textColor === "" ? "#000" : eventExtend.textColor}`,
        }}
      >
        {eventExtend.bodyText}
      </div>
    </div>
  );
};

SmallCalendarEvent.propTypes = {
  arg: PropTypes.object.isRequired,
};

export default SmallCalendarEvent;
