import PropTypes from "prop-types";
import { useTranslate } from "../../../../../language/i18n";
import { SubMenu as SubMenuType } from "../../../../../utils/menu/dataTypes";
import SubMenuItem from "./SubMenuItem";
import styles from "../MenuDrawer.module.scss";

const SubMenu = ({ subMenu, toggleMenu }: { subMenu: SubMenuType; toggleMenu: () => void }) => {
  const translate = useTranslate();

  return (
    <figure className={styles.subMenu}>
      {subMenu.title && <figcaption className={styles.title}>{translate(subMenu.title)}</figcaption>}
      <ul>
        {subMenu.items.map(item => (
          <SubMenuItem item={item} key={item.id} toggleMenu={toggleMenu} />
        ))}
      </ul>
    </figure>
  );
};

SubMenu.propTypes = {
  subMenu: PropTypes.object.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

export default SubMenu;
