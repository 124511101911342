import { createSlice } from "@reduxjs/toolkit";
import { AdditionalRequestState } from "../dataTypes";
import { resourceCalendarEventSliceName } from "./dataTypes";
import { resourceCalendarEventAdapter } from "./resourceCalendarEventAdapter";
import { addFetchResourceCalendarEventsReducers } from "./reducers/fetchResourceCalendarEvents";

const slice = createSlice({
  name: resourceCalendarEventSliceName,
  initialState: resourceCalendarEventAdapter.getInitialState<AdditionalRequestState>({}),
  reducers: {},
  extraReducers: builder => {
    addFetchResourceCalendarEventsReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
