import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import {
  EntityActionReducerBuilderInterface,
  FulfilledUpdateMethod,
  REQUEST_STATE,
  FULFILLED_UPDATE_METHOD,
} from "../../dataTypes";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import {
  ResponseResourceCalendarEvent,
  ResourceCalendarEvent,
  ResourceCalendarEventsFilter,
  resourceCalendarEventSliceName,
} from "../dataTypes";
import { resourceCalendarEventAdapter } from "../resourceCalendarEventAdapter";

const getResourceCalendarEvents = async (
  dispatch: AppDispatch,
  { filter }: { filter: ResourceCalendarEventsFilter },
) => {
  const url = await api.resourceCalendar.getCalendarEvents();

  const request = new Request(url, Method.POST);

  return await sendRequest(request, filter, await defaultRestOptions({ dispatch }));
};

export const fetchResourceCalendarEvents = createAsyncThunk<
  Array<ResponseResourceCalendarEvent>,
  { filter: ResourceCalendarEventsFilter; fulfilledUpdateMethod: FulfilledUpdateMethod }
>(resourceCalendarEventSliceName + "/fetchResourceCalendarEvents", getResourceCalendarEvents);

export const addFetchResourceCalendarEventsReducers = (
  builder: EntityActionReducerBuilderInterface<ResourceCalendarEvent>,
) => {
  builder.addCase(fetchResourceCalendarEvents.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessage = undefined;
  });
  builder.addCase(fetchResourceCalendarEvents.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod },
      },
    } = action;

    const resourceCalendarEvents = payload.map(mapFulfilledResponseObject);

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        resourceCalendarEventAdapter.setAll(state, resourceCalendarEvents);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        resourceCalendarEventAdapter.upsertMany(state, resourceCalendarEvents);
        break;
    }

    state.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(fetchResourceCalendarEvents.rejected, (state, action) => {
    state.requestState = REQUEST_STATE.REJECTED;
    state.errorMessage = action.payload?.message;
  });
};
