import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { resourceCalendarEventSliceName } from "./dataTypes";
import { resourceCalendarEventAdapter } from "./resourceCalendarEventAdapter";
import { ResourceCalendarEvent } from "./dataTypes";

const selectSliceState = (state: RootState) => state[appDataName][resourceCalendarEventSliceName];

const adapterSelectors = resourceCalendarEventAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessage;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectResourceCalendarEventEntities = adapterSelectors.selectEntities;
export const selectResourceCalendarEvents = adapterSelectors.selectAll;
export const selectResourceCalendarEvent =
  (id = "") =>
  (state: RootState) =>
    adapterSelectors.selectById(state, id);

export const selectResourceCalendarEventsById =
  (ids: string[] = []) =>
  (state: RootState) =>
    ids.reduce<ResourceCalendarEvent[]>((resourceCalendarEvents, id) => {
      const resourceCalendarEvent = selectResourceCalendarEvent(id)(state);

      return resourceCalendarEvent ? [...resourceCalendarEvents, resourceCalendarEvent] : resourceCalendarEvents;
    }, []);
