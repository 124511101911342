import PropType from "prop-types";
import { useEffect, useState } from "react";
import { useTranslate } from "../../../language/i18n";

import { ControlPointPassage } from "../../../features/controlPointPassage/dataTypes";
import styles from "./ControlPointPassageTable.module.scss";
import Pagination from "../../shared/Pagination/Pagination";

const ControlPointPassageTable = ({
  controlPointPassages,
  singlePoint = false,
  showDate = false,
  pageSize = 10,
}: {
  controlPointPassages: Array<ControlPointPassage>;
  singlePoint?: boolean;
  showDate?: boolean;
  pageSize?: number;
}) => {
  const translate = useTranslate();

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [controlPointPassages]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const controlPointPassagesOnPage = controlPointPassages
    .sort((passage1, passage2) => {
      if (passage1.date == passage2.date) {
        return passage1.time < passage2.time ? -1 : passage1.time > passage2.time ? 1 : 0;
      } else {
        return passage1.date < passage2.date ? -1 : passage1.date > passage2.date ? 1 : 0;
      }
    })
    .slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <>
      <table className={styles.shipmentsTable}>
        <tbody>
          <tr>
            {showDate ? <th>{translate("DATE")}</th> : null}
            <th>{translate("TIME")}</th>
            <th>{translate("DESCRIPTION")}</th>
            <th>{translate("PROJECT")}</th>
            {!singlePoint ? <th>{translate("TRAFFIC_NODE")}</th> : <th>{translate("SUBPROJECT")}</th>}
            <th>{translate("RESPONSIBLE")}</th>
          </tr>
          {controlPointPassagesOnPage.map(controlPointPassage => (
            <tr key={controlPointPassage.id} className={styles.shipmentsRow}>
              {showDate ? <td>{controlPointPassage.date}</td> : null}
              <td>{controlPointPassage.time.substring(0, 5)}</td>
              <td>{controlPointPassage.description}</td>
              <td>{controlPointPassage.projectId}</td>
              {!singlePoint ? (
                <td>{controlPointPassage.controlPointName}</td>
              ) : (
                <td>{controlPointPassage.subProject}</td>
              )}
              <td>{controlPointPassage.responsible}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        totalCount={controlPointPassages.length}
        pageSize={10}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        customCssClass={styles.pagination}
        page={currentPage}
      />
    </>
  );
};

ControlPointPassageTable.propTypes = {
  controlPointPassages: PropType.array.isRequired,
  singlePoint: PropType.bool,
  showDate: PropType.bool,
  pageSize: PropType.number,
};

export default ControlPointPassageTable;
