import { useCallback, useEffect, useRef } from "react";
import { PaginationFilter } from "../../utils/pagination";
import { FulfilledUpdateMethod, REQUEST_STATE } from "../../dataTypes";
import { useAppSelector } from "../../../app/hooks";
import { selectCount, selectErrorMessage, selectRequestState, selectResources } from "../resourceSelectors";
import useFetchResources from "./useFetchResources";

const useLazyGetResources = ({
  filter,
  fulfilledUpdateMethod,
  loadIfNotInitialized = false,
}: {
  filter?: Readonly<PaginationFilter>;
  fulfilledUpdateMethod: FulfilledUpdateMethod;
  loadIfNotInitialized?: boolean;
}) => {
  const fetchResources = useFetchResources();
  const hasInitalized = useRef(false);

  const requestState = useAppSelector(selectRequestState);
  const errorMessage = useAppSelector(selectErrorMessage);

  const resources = useAppSelector(selectResources);

  const count = useAppSelector(selectCount);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isError = requestState === REQUEST_STATE.REJECTED;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;

  const loadResources = useCallback(async () => {
    await fetchResources({ filter, fulfilledUpdateMethod });
  }, [fetchResources, filter, fulfilledUpdateMethod]);

  useEffect(() => {
    hasInitalized.current = false;
  }, [filter]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized && !hasInitalized.current) {
      hasInitalized.current = true;
      loadResources();
    }
  }, [isUninitialized, loadIfNotInitialized, loadResources]);

  return {
    resources,
    count,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    errorMessage,
    fetchResources: loadResources,
  };
};

export default useLazyGetResources;
