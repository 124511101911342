import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import setQueryFilters from "../../utils/setQueryFilters";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  PaginationResponse,
  FulfilledUpdateMethod,
  FULFILLED_UPDATE_METHOD,
  REQUEST_STATE,
} from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import {
  ResponseControlPointPassage,
  ControlPointPassage,
  ControlPointPassagesFilter,
  controlPointPassageSliceName,
} from "../dataTypes";
import { controlPointPassageAdapter } from "../controlPointPassageAdapter";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponsObject";

async function getControlPointPassages(dispatch: AppDispatch, filters?: ControlPointPassagesFilter) {
  const url = api.controlPointPassage.controlPointsPassages();

  setQueryFilters(url, filters);

  const request = new Request(url, Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchControlPointPassages = createAsyncThunk<
  PaginationResponse<ResponseControlPointPassage>,
  { filter?: ControlPointPassagesFilter; fulfilledUpdateMethod?: FulfilledUpdateMethod }
>(controlPointPassageSliceName + "/fetchControlPointPassages", (dispatch, { filter }) =>
  getControlPointPassages(dispatch, filter),
);

export const addFetchControlPointPassagesReducers = (
  builder: EntityActionReducerBuilderInterface<ControlPointPassage, AdditionalPaginationState>,
) => {
  builder.addCase(fetchControlPointPassages.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessage = undefined;
  });
  builder.addCase(fetchControlPointPassages.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod = FULFILLED_UPDATE_METHOD.UPSERT_MANY },
      },
    } = action;

    const { count, page } = payload;

    const controlPointsPassages = page.map(mapFulfilledResponseObject);

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        controlPointPassageAdapter.setAll(state, controlPointsPassages);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        controlPointPassageAdapter.upsertMany(state, controlPointsPassages);
        break;
    }

    state.count = count;
    state.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(fetchControlPointPassages.rejected, (state, action) => {
    state.requestState = REQUEST_STATE.REJECTED;
    state.errorMessage = action.payload?.message;
  });
};
