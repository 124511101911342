import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { File, fileSliceName } from "./dataTypes";
import { fileAdapter } from "./fileAdapter";

const selectSliceState = (state: RootState) => state[appDataName][fileSliceName];

const adapterSelectors = fileAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessage;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectFileEntities = adapterSelectors.selectEntities;
export const selectFiles = adapterSelectors.selectAll;
export const selectFile =
  (id = "") =>
  (state: RootState) =>
    adapterSelectors.selectById(state, id);
export const selectFilesById =
  (ids: string[] = []) =>
  (state: RootState) =>
    ids.reduce((files, id) => {
      const file = adapterSelectors.selectById(state, id);

      return file ? [...files, file] : files;
    }, [] as File[]);
