import { MenuItem } from "./dataTypes";
import { cloneDeep } from "lodash";
import pages from "../pages";

const asMenuItem = <T>(menuItems: { [K in keyof T]: { items: MenuItem[] } }) => menuItems;

const CONFIGS = asMenuItem({
  MENU: {
    items: [
      {
        label: "HOME",
        to: pages.HOME,
        id: "HOME",
      },
      {
        label: "Företagsinställningar",
        to: pages.COMPANY_SETTINGS,
        id: "COMPANY_SETTINGS",
      },
      {
        label: "Projektinställningar",
        to: pages.PROJECT_SETTINGS,
        id: "PROJECT_SETTINGS",
      },
      {
        label: "Logisticscenter",
        to: pages.LOGISTICS_CENTER,
        id: "LOGISTICS_CENTER",
      },
      /* Navbar menu*/
    ],
  },
  MENU_DRAWER: {
    items: [
      {
        label: "HOME",
        to: pages.HOME,
        id: "HOME",
      },
      /* Drawer menu*/
    ],
  },
});

export const createMenu = (conf: keyof typeof CONFIGS) => {
  const menuConfig = CONFIGS[conf];

  return cloneDeep(menuConfig.items);
};
