import { AdditionalRequestState, IdObject } from "../dataTypes";

export const resourceCalendarEventSliceName = "resourceCalendarEvent";

export interface ResponseResourceCalendarEvent extends IdObject {
  end: string;
  start: string;
  pattern: string;
  title: string;
  tooltipText?: string;
  headerColor: string;
  allDay: boolean;
  icon: string;
  textColor: string;
  backgroundColor: string;
  bodyText: string;
}

export interface ResourceCalendarEvent extends ResponseResourceCalendarEvent, AdditionalRequestState {}

export interface ResourceCalendarEventsFilter {
  fromDate: string;
  toDate: string;
  selectedContractorId?: string;
  selectedProjectId?: string;
  resourceCode?: string;
}
