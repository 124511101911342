import { useEffect, useRef } from "react";
import useForceUpdate from "../../../hooks/useForceUpdate";
import useLazyGetResourceCalendarSettings from "./useLazyGetResourceCalendarSettings";

const useGetResourceCalendarSettings = () => {
  const { isLoading: isLazyLoading, fetchResourceCalendarSettings, ...rest } = useLazyGetResourceCalendarSettings({});
  const forceUpdate = useForceUpdate();
  const initiationStatus = useRef<"initiating" | "initated">();

  useEffect(() => {
    const initiateResourceCalendarSettings = async () => {
      if (!initiationStatus.current) {
        initiationStatus.current = "initiating";
        forceUpdate();
        await fetchResourceCalendarSettings();
        initiationStatus.current = "initated";
      }
    };

    initiateResourceCalendarSettings();
  }, [fetchResourceCalendarSettings, forceUpdate]);

  const isLoading = !initiationStatus.current || isLazyLoading;

  return { ...rest, isLoading };
};

export default useGetResourceCalendarSettings;
