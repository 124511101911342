import { useCallback, useEffect } from "react";
import isEqual from "react-fast-compare";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { FulfilledUpdateMethod, RequestState, REQUEST_STATE } from "../../dataTypes";
import { ResourceCalendarEventsFilter } from "../dataTypes";
import { fetchResourceCalendarEvents } from "../reducers/fetchResourceCalendarEvents";
import { selectRequestState } from "../resourceCalendarEventSelectors";
import { useValidateUserIsLoggedIn } from "../../utils/useValidateUserIsLoggedIn";
import { setError } from "../../dialog/dialogSlice";
import cloneDeep from "lodash.clonedeep";

let filterRef: ResourceCalendarEventsFilter;
let requestStateRef: RequestState | undefined;

const useFetchResourceCalendarEvents = () => {
  const dispatch = useAppDispatch();
  const result = useValidateUserIsLoggedIn();

  const requestState = useAppSelector(selectRequestState);

  if (requestStateRef === undefined) requestStateRef = requestState;

  useEffect(() => {
    requestStateRef = requestState;
  }, [requestState]);

  const loadResourceCalendarEvents = useCallback(
    async ({
      filter,
      fulfilledUpdateMethod,
    }: {
      filter: ResourceCalendarEventsFilter;
      fulfilledUpdateMethod: FulfilledUpdateMethod;
    }) => {
      if (result.isError) {
        dispatch(setError({ value: result.errorMessage }));
      } else {
        if (!isEqual(filter, filterRef) || requestStateRef !== REQUEST_STATE.PENDING) {
          requestStateRef = REQUEST_STATE.PENDING;
          filterRef = cloneDeep(filter);

          const { payload } = await dispatch(fetchResourceCalendarEvents({ filter, fulfilledUpdateMethod }));

          if (payload && Array.isArray(payload)) {
            return payload.map(({ id }) => id);
          }
        }
      }
    },
    [dispatch, result],
  );

  return loadResourceCalendarEvents;
};

export default useFetchResourceCalendarEvents;
