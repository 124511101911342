import { AdditionalRequestState, IdObject } from "../dataTypes";

export const resourceSliceName = "resource";

export interface ResponseResource extends IdObject {
  companyId: string;
  facilityId: string;
  resourceCode: string;
  name: string;
}

export interface Resource extends ResponseResource, AdditionalRequestState {}
