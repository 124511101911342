import { useState, useEffect, useRef, ReactNode } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Collapse.module.scss";
import { ValueOf } from "../../../utils/dataTypes";

export const EASING = {
  LINEAR: "linear",
  EASE: "ease",
  EASE_IN: "ease-in",
  EASE_OUT: "ease-out",
  EASE_IN_OUT: "ease-in-out",
} as const;

const Collapse = ({
  children,
  visible = false,
  duration = "auto",
  easing = EASING.EASE_IN_OUT,
  customCssClass,
}: {
  children?: ReactNode;
  visible?: boolean;
  duration?: "auto" | number;
  easing?: ValueOf<typeof EASING>;
  customCssClass?: string;
}) => {
  const [targetHeight, setTargetHeight] = useState(0);
  const [shouldRender, setShouldRender] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (visible) {
      setShouldRender(true);
    }
  }, [visible]);

  const onTransitionEnd = () => {
    if (!visible) setShouldRender(false);
  };

  useEffect(() => {
    if (visible && shouldRender) {
      setTargetHeight(ref.current?.scrollHeight ?? 0);
    } else {
      setTargetHeight(0);
    }
  }, [visible, shouldRender]);

  /* 
  Calculation taken from MUI collapse component- getAutoHeightDuration 
  https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/styles/transitions.js
   */
  const getDuration = () => {
    const height = ref.current?.scrollHeight;

    if (!height) {
      return 0;
    }

    const constant = height / 36;

    return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10);
  };

  const innerStyles: React.HTMLAttributes<HTMLDivElement>["style"] = {
    maxHeight: targetHeight,
    transitionDuration: (duration === "auto" ? getDuration() : duration) + "ms",
    transitionTimingFunction: easing,
  };

  return shouldRender ? (
    <div
      className={classNames(styles.collapse, customCssClass)}
      style={innerStyles}
      ref={ref}
      onTransitionEnd={onTransitionEnd}
    >
      {children}
    </div>
  ) : (
    <></>
  );
};

Collapse.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.node,
  customCssClass: PropTypes.string,
  duration: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(["auto"])]),
  easing: PropTypes.oneOf(Object.values(EASING)),
};

export default Collapse;
