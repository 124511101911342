import PropTypes from "prop-types";

import Calendar from "./TypesSlideshow/Calendar";
import Url from "./TypesSlideshow/Url";
import File from "./TypesSlideshow/File";
import styles from "./PanelSlideshow.module.scss";
import { Slides, CalendarEventInfo, SLIDESHOW_TYPES } from "../dataTypes";

import { useCallback, useEffect, useState } from "react";

const OFFSETS = {
  WIDTH: "10px",
  HEIGHT: "20px",
} as const;

const PanelSlideshow = ({
  height,
  width,
  headerHeight,
  slides,
  calendarEvents,
}: {
  height: string;
  width: string;
  headerHeight: string;
  slides: Slides[];
  calendarEvents: CalendarEventInfo[];
}) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  const nextSlide = useCallback(() => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  }, [current, length]);

  useEffect(() => {
    const currentSlideTime = slides[current].displayTime;
    const intervals = setInterval(nextSlide, 1000 * currentSlideTime);

    return () => clearInterval(intervals);
  }, [slides, current, nextSlide]);

  return (
    <div
      className={styles.slideshowWindow}
      style={{ height: window.innerHeight > 600 ? `calc(${height} - ${headerHeight})` : height }}
    >
      {slides
        .sort((a: Slides, b: Slides) => a.sequence - b.sequence)
        .map((slide, index) => {
          return (
            <div
              style={{
                width: `calc(${width} - ${OFFSETS.WIDTH})`,
                height: window.innerHeight > 600 ? `calc(${height} - ${headerHeight} - ${OFFSETS.HEIGHT})` : height,
              }}
              className={index === current ? styles.slideImageActive : styles.slideImage}
              key={index}
            >
              {(() => {
                switch (slide.type) {
                  case SLIDESHOW_TYPES.EXTERNAL_PAGE:
                    return <Url slide={slide} />;

                  case SLIDESHOW_TYPES.IMAGE:
                    return <File file={slide.file} />;

                  case SLIDESHOW_TYPES.CALENDAR:
                    return (
                      <Calendar
                        height={height}
                        headerHeight={headerHeight}
                        workDayStart={slide.workDayStart ? slide.workDayStart : "7:00"}
                        workDayEnd={slide.workDayEnd ? slide.workDayEnd : "16:00"}
                        slide={slide}
                        calendarEvent={calendarEvents.filter((event: CalendarEventInfo) =>
                          slide.calendarEvents?.ids.includes(event.id),
                        )}
                      />
                    );

                  default:
                    return null;
                }
              })()}
            </div>
          );
        })}
    </div>
  );
};

PanelSlideshow.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  headerHeight: PropTypes.string.isRequired,
  slides: PropTypes.arrayOf(PropTypes.object).isRequired,
  calendarEvents: PropTypes.arrayOf(
    PropTypes.shape({
      end: PropTypes.string.isRequired,
      start: PropTypes.string.isRequired,
      pattern: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      headerColor: PropTypes.string.isRequired,
      allDay: PropTypes.bool.isRequired,
      icon: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
      backgroundColor: PropTypes.string.isRequired,
      bodyText: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default PanelSlideshow;
