import queryString from "query-string";
import { useCallback, useEffect } from "react";

import storage from "./store/storage";
import { REMEMBER_ME, SESSION_ID } from "./store/storeVariables";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectIsInitialized } from "../features/session/sessionSelectors";
import useLogIn from "../features/session/hooks/useLogIn";
import useFetchSessionData from "../features/session/hooks/useFetchSessionData";
import { saveSessionId } from "../features/session/utils/saveSessionId";
import { setIsInitialized } from "../features/session/sessionSlice";
import { setRememberMe } from "../features/session/utils/setRememberMe";
import { setLoginToken } from "../features/session/utils/setLoginToken";

const useInitializeStandardLogInSession = (useMylocLogin?: boolean) => {
  const dispatch = useAppDispatch();
  const loadSessionData = useFetchSessionData();
  const sessionInitialized = useAppSelector(selectIsInitialized);

  const initializeStandardLoginSession = useCallback(async () => {
    const rememberMe = await storage.loadItem(REMEMBER_ME);

    setRememberMe(dispatch, !!rememberMe);

    const sessionId = await storage.loadItem(SESSION_ID);

    if (sessionId) {
      const payload = await loadSessionData();

      if (payload) {
        if (!payload.isError) saveSessionId(payload.sessionId);
        else saveSessionId(undefined, rememberMe);
      }
    }

    dispatch(setIsInitialized(true));
  }, [dispatch, loadSessionData]);

  useEffect(() => {
    if (useMylocLogin === false && !sessionInitialized) initializeStandardLoginSession();
  }, [initializeStandardLoginSession, sessionInitialized, useMylocLogin]);
};

const useInitializeMylocLoginSession = (useMylocLogin?: boolean) => {
  const dispatch = useAppDispatch();
  const { loginWithMylocToken, loginWithAccessKey } = useLogIn();
  const loadSessionData = useFetchSessionData();
  const sessionInitialized = useAppSelector(selectIsInitialized);

  const doLoginWithToken = useCallback(
    async (mylocLoginToken: string) => {
      await loginWithMylocToken({ mylocLoginToken });
    },
    [loginWithMylocToken],
  );

  const doLoginWithAccessKey = useCallback(
    async (accessKey: string) => {
      await loginWithAccessKey({ accessKey, persistent: true });
    },
    [loginWithAccessKey],
  );

  const mylocAuthentication = useCallback(
    async (
      mylocLoginToken?: ReturnType<typeof queryString["parse"]>[keyof ReturnType<typeof queryString["parse"]>],
      accessKey?: ReturnType<typeof queryString["parse"]>[keyof ReturnType<typeof queryString["parse"]>],
    ) => {
      if (mylocLoginToken) {
        setLoginToken(dispatch, mylocLoginToken as string, false);
        await doLoginWithToken(mylocLoginToken as string);
      }

      if (accessKey) {
        await doLoginWithAccessKey(accessKey as string);
      }
    },
    [dispatch, doLoginWithToken, doLoginWithAccessKey],
  );

  const initializeMylocLoginSession = useCallback(async () => {
    const query = queryString.parse(location.search);

    //If a virtualSessionId exists, use it instead of logging in again
    if (query.virtualSessionId) {
      saveSessionId(encodeURIComponent(query.virtualSessionId as string));
      await loadSessionData();
    } else await mylocAuthentication(query.mylocLoginToken, query.accessKey);

    dispatch(setIsInitialized(true));
  }, [dispatch, loadSessionData, mylocAuthentication]);

  useEffect(() => {
    if (useMylocLogin === true && !sessionInitialized) initializeMylocLoginSession();
  }, [initializeMylocLoginSession, sessionInitialized, useMylocLogin]);
};

const useInitializeLogIn = () => {
  const useMylocLogin = isMylocLogin();

  useInitializeStandardLogInSession(useMylocLogin);
  useInitializeMylocLoginSession(useMylocLogin);
};

const useInit = () => {
  useInitializeLogIn();
};

const isMylocLogin = () => {
  const mylocToken = queryString.parse(location.search).mylocLoginToken ?? undefined;
  const accessKey = queryString.parse(location.search).accessKey ?? undefined;

  return mylocToken !== undefined || accessKey !== undefined;
};

export default useInit;
