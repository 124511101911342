import { FiltrationFilter, FILTRATION_FILTER_DISCRIMINATOR } from "./pagination";

const isFiltrationFilter = (object?: any): object is FiltrationFilter =>
  object?.discriminator === FILTRATION_FILTER_DISCRIMINATOR;

const isFiltrationFilterArray = (object?: any): object is FiltrationFilter[] => {
  return object && Array.isArray(object) && object.every(isFiltrationFilter);
};

export { isFiltrationFilter, isFiltrationFilterArray };
