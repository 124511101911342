import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import setQueryFilters from "../../../utils/setQueryFilters";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  PaginationResponse,
  FulfilledUpdateMethod,
  REQUEST_STATE,
  FULFILLED_UPDATE_METHOD,
} from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { ResponseControlPoint, ControlPoint, ControlPointsFilter, controlPointSliceName } from "../dataTypes";
import { controlPointAdapter } from "../controlPointAdapter";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponsObject";

async function getControlPoints(dispatch: AppDispatch, filters?: ControlPointsFilter) {
  const url = api.controlPoint.controlPoints();

  setQueryFilters(url, filters);

  const request = new Request(url, Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchControlPoints = createAsyncThunk<
  PaginationResponse<ResponseControlPoint>,
  { filter?: ControlPointsFilter | undefined; fulfilledUpdateMethod?: FulfilledUpdateMethod }
>(controlPointSliceName + "/fetchControlPoints", (dispatch, { filter }) => getControlPoints(dispatch, filter));

export const addFetchControlPointsReducers = (
  builder: EntityActionReducerBuilderInterface<ControlPoint, AdditionalPaginationState>,
) => {
  builder.addCase(fetchControlPoints.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessage = undefined;
  });
  builder.addCase(fetchControlPoints.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod = FULFILLED_UPDATE_METHOD.UPSERT_MANY },
      },
    } = action;

    const { count, page } = payload;

    const controlPoints = page.map(mapFulfilledResponseObject);

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        controlPointAdapter.setAll(state, controlPoints);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        controlPointAdapter.upsertMany(state, controlPoints);
        break;
    }

    state.count = count;
    state.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(fetchControlPoints.rejected, (state, action) => {
    state.requestState = REQUEST_STATE.REJECTED;
    state.errorMessage = action.payload?.message;
  });
};
