import PropTypes from "prop-types";
import styles from "./File.module.scss";
import { ImageFile } from "../../dataTypes";

const File = ({ file }: { file?: ImageFile }) => {
  if (!file) return null;

  return (
    <>
      <img className={styles.image} src={file.url} alt={file.name} />;
    </>
  );
};

File.propTypes = {
  slide: PropTypes.shape({
    name: PropTypes.string,
    sequence: PropTypes.number,
    url: PropTypes.string,
    createdTimestamp: PropTypes.number,
    id: PropTypes.string,
  }),
};

export default File;
