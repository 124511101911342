import { PaginationFilter, SortFilter } from "../utils/pagination";
import { AdditionalRequestState, FetchedIdObject, IdObject, ReferenceIds } from "../dataTypes";

export const pageContentSliceName = "pageContent";

export interface ResponsePageContent extends IdObject {
  title: string;
  text: string;
  hyperlinkTitle: string;
  fileTitle: string;
  publishDate: number;
}

export interface PageContent extends ResponsePageContent, AdditionalRequestState, FetchedIdObject {
  files?: ReferenceIds;
  hyperlinks?: ReferenceIds;
}

interface PageContentFilesSorting extends SortFilter {
  id: "sequence";
}

export interface PageContentFilesFilter extends PaginationFilter {
  sort?: PageContentFilesSorting[];
}

interface PageContentHyperlinksSorting extends SortFilter {
  id: "sequence";
}

export interface PageContentHyperlinksFilter extends PaginationFilter {
  sort?: PageContentHyperlinksSorting[];
}
