import PropTypes from "prop-types";
import styles from "./Url.module.scss";
import { Slides } from "../../dataTypes";

const Url = ({ slide }: { slide: Slides }) => {
  return (
    <iframe
      title={slide.externalUrl}
      className={styles.website}
      src={slide.externalUrl}
      scrolling="no"
      frameBorder="0"
    />
  );
};

Url.propTypes = {
  slide: PropTypes.object.isRequired,
};

export default Url;
