import PropTypes from "prop-types";
import styles from "./ClickableMarker.module.scss";
import colors from "../../../style/colors.scss";

const ClickableMarker = ({
  label,
  selected,
  hasPassages,
  scaling,
  clickHandler,
  selectedColor,
}: {
  lat: string;
  lng: string;
  label: string;
  selected: boolean;
  hasPassages: boolean | undefined;
  scaling: number | undefined;
  clickHandler: () => void;
  selectedColor: string;
}) => {
  let backgroundColor = colors.red2;

  if (!hasPassages) {
    backgroundColor = colors.grey;
  }

  if (selected) {
    backgroundColor = selectedColor;
  }

  return (
    <div>
      <button
        style={{
          height: `${scaling ? scaling * 20 : 20}px`, // bad
          width: `${scaling ? scaling * 20 : 20}px`,
          backgroundColor: `${backgroundColor}`,
        }}
        className={styles.marker}
        onClick={clickHandler}
      ></button>
      <span className={styles.text}>{label}</span>
    </div>
  );
};

ClickableMarker.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  hasPassages: PropTypes.bool,
  scaling: PropTypes.number,
  clickHandler: PropTypes.func.isRequired,
  lat: PropTypes.string.isRequired,
  lng: PropTypes.string.isRequired,
  selectedColor: PropTypes.string.isRequired,
} as unknown;

export default ClickableMarker;
