import Page from "../Page/Page";
import { useTranslate } from "../../../language/i18n";
import styles from "./NotFoundPage.module.scss";

const NotFoundPage = () => {
  const translate = useTranslate();

  return (
    <Page>
      <h1 className={styles.title}>{translate("PAGE_NOT_FOUND")}</h1>
      <p className={styles.description}>{translate("PAGE_NOT_FOUND_DESCRIPTION")}</p>
    </Page>
  );
};

export default NotFoundPage;
