import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { ControlPointPassage, controlPointPassageSliceName } from "./dataTypes";
import { controlPointPassageAdapter } from "./controlPointPassageAdapter";

const selectSliceState = (state: RootState) => state[appDataName][controlPointPassageSliceName];

const adapterSelectors = controlPointPassageAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessage;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectCount = (state: RootState) => selectSliceState(state).count;

export const selectControlPointPassageEntities = adapterSelectors.selectEntities;
export const selectControlPointPassages = adapterSelectors.selectAll;

export const selectControlPointPassage =
  (id = "") =>
  (state: RootState) =>
    adapterSelectors.selectById(state, id);
export const selectControlPointPassagesById =
  (ids: string[] = []) =>
  (state: RootState) =>
    ids.reduce((passages, id) => {
      const passage = adapterSelectors.selectById(state, id);

      return passage ? [...passages, passage] : passages;
    }, [] as ControlPointPassage[]);
