import { useCallback, useEffect, useRef } from "react";
import { ResourceCalendarEventsFilter } from "../dataTypes";
import { FulfilledUpdateMethod, REQUEST_STATE } from "../../dataTypes";
import { useAppSelector } from "../../../app/hooks";
import {
  selectErrorMessage,
  selectRequestState,
  selectResourceCalendarEvents,
} from "../resourceCalendarEventSelectors";
import useFetchResourceCalendarEvents from "./useFetchResourceCalendarEvents";

const useLazyGetResourceCalendarEvents = ({
  filter,
  fulfilledUpdateMethod,
  loadIfNotInitialized = false,
}: {
  filter: Readonly<ResourceCalendarEventsFilter>;
  fulfilledUpdateMethod: FulfilledUpdateMethod;
  loadIfNotInitialized?: boolean;
}) => {
  const fetchResourceCalendarEvents = useFetchResourceCalendarEvents();
  const hasInitalized = useRef(false);

  const requestStatus = useAppSelector(selectRequestState);
  const errorMessage = useAppSelector(selectErrorMessage);

  const resourceCalendarEvents = useAppSelector(selectResourceCalendarEvents);

  const isUninitialized = requestStatus === undefined;
  const isLoading = requestStatus === REQUEST_STATE.PENDING;
  const isError = requestStatus === REQUEST_STATE.REJECTED;
  const isSuccess = requestStatus === REQUEST_STATE.FULFILLED;

  const loadResourceCalendarEvents = useCallback(async () => {
    await fetchResourceCalendarEvents({ filter, fulfilledUpdateMethod });
  }, [fetchResourceCalendarEvents, filter, fulfilledUpdateMethod]);

  useEffect(() => {
    hasInitalized.current = false;
  }, [filter]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized && !hasInitalized.current) {
      hasInitalized.current = true;
      loadResourceCalendarEvents();
    }
  }, [isUninitialized, loadIfNotInitialized, loadResourceCalendarEvents]);

  return {
    resourceCalendarEvents,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    errorMessage,
    fetchResourceCalendarEvents: loadResourceCalendarEvents,
  };
};

export default useLazyGetResourceCalendarEvents;
