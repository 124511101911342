import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { fetchHyperlinks } from "../../pageContent/reducers/fetchHyperlinks";
import { Hyperlink } from "../dataTypes";
import { hyperlinkAdapter } from "../hyperlinkAdapter";

export const addFetchHyperlinksForPageContentReducers = (builder: EntityActionReducerBuilderInterface<Hyperlink>) => {
  builder.addCase(fetchHyperlinks.fulfilled, (state, action) => {
    const { payload } = action;

    hyperlinkAdapter.upsertMany(
      state,
      payload.page.map(responseObject => ({
        ...responseObject,
        fetchedTimestamp: Date.now(),
        requestStatus: REQUEST_STATE.FULFILLED,
        errorMessage: undefined,
      })),
    );
  });
};
