import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useTranslate } from "../../../language/i18n";
import { RequireSelected } from "../../../utils/dataTypes";
import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { OverloadedReturnType, RequestState, REQUEST_STATE } from "../../dataTypes";
import { setError } from "../../dialog/dialogSlice";
import { getValidationResult } from "../../utils/getValidationResults";
import { LogInRequest } from "../dataTypes";
import { logIn as logInAction } from "../reducers/logIn";
import { selectRequestState } from "../sessionSelectors";
import { saveSessionId } from "../utils/saveSessionId";

import { ResponseSessionData } from "../dataTypes";

type LogInRequestType = RequireSelected<
  Pick<LogInRequest, "username" | "password" | "persistent">,
  "username" | "password"
>;

type LogInWithMylocTokenType = RequireSelected<Pick<LogInRequest, "mylocLoginToken" | "persistent">, "mylocLoginToken">;
type LogInWithAccessKeyType = RequireSelected<Pick<LogInRequest, "accessKey" | "persistent">, "accessKey">;

let requestStateRef: RequestState | undefined;

const useLogIn = () => {
  const dispatch = useAppDispatch();
  const translate = useTranslate();

  const rememberMe = true; //useAppSelector(selectRememberMe);

  const requestState = useAppSelector(selectRequestState);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;
  const isError = requestState === REQUEST_STATE.REJECTED;

  if (requestStateRef === undefined) requestStateRef = requestState;

  useEffect(() => {
    requestStateRef = requestState;
  }, [requestState]);

  const validateLogIn = useCallback(
    (
      additionalValidation: (request: LogInRequest) => OverloadedReturnType<typeof getValidationResult>,
      request?: Partial<LogInRequest>,
    ) => {
      if (!request) return getValidationResult(true, translate("DATA_MISSING"), HttpStatusCodes.BAD_REQUEST);
      return additionalValidation(request);
    },
    [translate],
  );

  const validateStandardLogin = useCallback(
    (request: Partial<LogInRequest>) => {
      if (!request.username)
        return getValidationResult(true, translate("USERNAME_MISSING"), HttpStatusCodes.BAD_REQUEST);
      if (!request.password)
        return getValidationResult(true, translate("PASSWORD_MISSING"), HttpStatusCodes.BAD_REQUEST);

      return getValidationResult(false);
    },
    [translate],
  );

  const validateLoginWithMylocToken = useCallback(
    (request: Partial<LogInRequest>) => {
      if (!request.mylocLoginToken)
        return getValidationResult(true, translate("TOKEN_MISSING"), HttpStatusCodes.BAD_REQUEST);
      return getValidationResult(false);
    },
    [translate],
  );

  const validateLoginWithAccessKey = useCallback(
    (request: Partial<LogInRequest>) => {
      if (!request.accessKey)
        return getValidationResult(true, translate("ACCESS_KEY_MISSING"), HttpStatusCodes.BAD_REQUEST);
      return getValidationResult(false);
    },
    [translate],
  );

  const _logIn = useCallback(
    async (
      logInRequest: LogInRequest,
      additionalValidation: (request: LogInRequest) => OverloadedReturnType<typeof getValidationResult>,
    ) => {
      const result = validateLogIn(additionalValidation, logInRequest);

      if (result.isError) {
        dispatch(setError({ value: result.errorMessage }));
      } else {
        if (requestStateRef !== REQUEST_STATE.PENDING) {
          requestStateRef = REQUEST_STATE.PENDING;

          const payload = (await dispatch(logInAction(logInRequest))).payload;

          const checkValidResSessionData: Partial<ResponseSessionData> = payload as Partial<ResponseSessionData>;

          if (payload && checkValidResSessionData.id) {
            saveSessionId(checkValidResSessionData.id, rememberMe);
          }
        }
      }
    },
    [dispatch, rememberMe, validateLogIn],
  );

  const logIn = useCallback(
    async (logInRequest: LogInRequestType) => await _logIn(logInRequest, validateStandardLogin),
    [_logIn, validateStandardLogin],
  );

  const loginWithMylocToken = useCallback(
    async (logInRequest: LogInWithMylocTokenType) => await _logIn(logInRequest, validateLoginWithMylocToken),
    [_logIn, validateLoginWithMylocToken],
  );

  const loginWithAccessKey = useCallback(
    async (logInRequest: LogInWithAccessKeyType) => await _logIn(logInRequest, validateLoginWithAccessKey),
    [_logIn, validateLoginWithAccessKey],
  );

  return { logIn, loginWithMylocToken, loginWithAccessKey, isUninitialized, isLoading, isSuccess, isError };
};

export default useLogIn;
