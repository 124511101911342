function getMonth(date: Date) {
  const month = date.getMonth() + 1;

  return month < 10 ? `0${month}` : month;
}

function getDate(date: Date) {
  const day = date.getDate();

  return day < 10 ? `0${day}` : day;
}

function getHours(date: Date) {
  const hour = date.getHours();

  return hour < 10 ? `0${hour}` : hour;
}

function getMinutes(date: Date) {
  const minutes = date.getMinutes();

  return minutes < 10 ? `0${minutes}` : minutes;
}

export function getTimestamp(timestamp?: number) {
  if (timestamp) {
    const date = new Date(timestamp);

    return `${date.getFullYear()}-${getMonth(date)}-${getDate(date)} ${getHours(date)}:${getMinutes(date)}`;
  }

  return "";
}
