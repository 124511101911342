export interface SlideshowType {
  slideshow: SlideshowItem;
  calendarEvents: CalendarEventInfo[];
}

export interface SlideshowItem {
  companyLogoUrl: string;
  projectId: string;
  projectName: string;
  slides: Slides[];
}

export interface Slides {
  type: string;
  displayTime: number;
  sequence: number;
  showWeekends?: boolean;
  viewMode?: string;
  startDate?: string;
  workDayStart?: string;
  workDayEnd?: string;
  projectId?: string;
  projectName?: string;
  calendarEvents?: CalendarEventsSlides;
  file?: ImageFile;
  externalUrl?: string;
}

export interface CalendarEventsSlides {
  count: number;
  ids: string[];
}

export interface ImageFile {
  name: string;
  sequence: number;
  url: string;
  createdTimestamp: number;
  id: string;
}

export interface CalendarEventInfo {
  end: string;
  start: string;
  pattern: string;
  title: string;
  headerColor: string;
  allDay: boolean;
  icon: string;
  textColor: string;
  backgroundColor: string;
  bodyText: string;
  id: string;
}

export const SLIDESHOW_TYPES = {
  EXTERNAL_PAGE: "ExternalPage",
  IMAGE: "Image",
  CALENDAR: "calendar",
} as const;
