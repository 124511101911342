import patterns from "../assets/images/patterns/Patterns.module.scss";

const useGetCalendarPattern = (pattern: string) => {
  const PATTERNS = {
    BACKDIAGONAL: "backdiagonal",
    BUBBLES: "bubbles",
    CROSSES: "crosses",
    DOTS: "dots",
    GRID: "grid",
    HORIZONTALZIGZAG: "horizontalzigzag",
    HORIZONTAL: "horizontal",
    RHOMBS: "rhombs",
    VERTICALZIGZAG: "verticalzigzag",
    VERTICAL: "vertical",
  } as const;

  switch (pattern) {
    case PATTERNS.BACKDIAGONAL:
      return patterns.backdiagonal;
    case PATTERNS.BUBBLES:
      return patterns.bubbles;
    case PATTERNS.CROSSES:
      return patterns.crosses;
    case PATTERNS.DOTS:
      return patterns.dots;
    case PATTERNS.GRID:
      return patterns.grid;
    case PATTERNS.HORIZONTALZIGZAG:
      return patterns.horizontalzigzag;
    case PATTERNS.HORIZONTAL:
      return patterns.horizontal;
    case PATTERNS.RHOMBS:
      return patterns.rhombs;
    case PATTERNS.VERTICALZIGZAG:
      return patterns.verticalzigzag;
    case PATTERNS.VERTICAL:
      return patterns.vertical;

    default:
      return null;
  }
};

export default useGetCalendarPattern;
