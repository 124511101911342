import { useCallback, useEffect } from "react";
import { useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE } from "../../dataTypes";
import {
  selectErrorMessage,
  selectRequestState,
  selectResourceCalendarSettings,
} from "../resourceCalendarSettingsSelectors";
import useFetchResourceCalendarSettings from "./useFetchResourceCalendarSettings";

const useLazyGetResourceCalendarSettings = ({ loadIfNotInitialized }: { loadIfNotInitialized?: boolean }) => {
  const fetchResourceCalendarSettings = useFetchResourceCalendarSettings();

  const requestState = useAppSelector(selectRequestState);
  const errorMessage = useAppSelector(selectErrorMessage);

  const resourceCalendarSettings = useAppSelector(selectResourceCalendarSettings);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;
  const isError = requestState === REQUEST_STATE.REJECTED;

  const loadResourceCalendarSettings = useCallback(async () => {
    await fetchResourceCalendarSettings();
  }, [fetchResourceCalendarSettings]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized) loadResourceCalendarSettings();
  }, [isUninitialized, loadIfNotInitialized, loadResourceCalendarSettings]);

  return {
    resourceCalendarSettings,
    isUninitialized,
    isLoading,
    isSuccess,
    isError,
    errorMessage,
    fetchResourceCalendarSettings: loadResourceCalendarSettings,
  };
};

export default useLazyGetResourceCalendarSettings;
